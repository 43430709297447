
.widget-preview-engine-cell {

  margin-bottom: 15px;
  border: 1px solid #eee;
  border-radius: 4px;

  .engine-title {
    border-bottom: 1px solid #eeeeee;
    display: flex;
    justify-content: space-between;
    padding: 10px;

  }

  .engine-content {
    padding: 10px;

    .castle-widget-info-cell {

      .info-cell-name {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-weight: normal;
      }
    }

  }


}

@primary-color: #215891;
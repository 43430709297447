.border-radius-10 {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.br10 {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.border-radius-9 {
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}
.br9 {
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}
.border-radius-8 {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.br8 {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.border-radius-7 {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}
.br7 {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}
.border-radius-6 {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.br6 {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.border-radius-5 {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.br5 {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.border-radius-4 {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.br4 {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.border-radius-3 {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.br3 {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.border-radius-2 {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.br2 {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.border-radius-1 {
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
.br1 {
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
.border-dash {
  border: 1px dashed #ccc;
}
.border {
  border: 1px solid #eee;
}
.border-danger {
  border: 1px solid #FF756F;
}
.border-bottom {
  border-bottom: 1px solid #F9F9F9;
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn-action {
  margin: 0 3px;
  display: inline-block;
  opacity: 0.85;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
  cursor: pointer;
  outline: none;
}
.btn-action:hover {
  text-decoration: none;
  opacity: 1;
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.btn-action:focus {
  outline: none;
}
.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #286090;
  border-color: #122b40;
}
.btn-primary:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}
.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.action-buttons a {
  margin: 0 3px;
  display: inline-block;
  opacity: 0.85;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
  cursor: pointer;
  outline: none;
}
.action-buttons a:hover {
  text-decoration: none;
  opacity: 1;
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.action-buttons a:focus {
  outline: none;
}
.cursor {
  cursor: pointer;
}
.bg-primary {
  background-color: #215891;
  color: white;
}
.bg-success {
  background-color: #67C23A;
  color: white;
}
.bg-info {
  background-color: #2DB7F5;
  color: white;
}
.bg-warning {
  background-color: #E6A23C;
  color: white;
}
.bg-danger {
  background-color: #FF756F;
  color: white;
}
.bg-gray {
  background-color: #c2c2c2;
  color: white;
}
.bg-laxative {
  background-color: #B3EE3A;
  color: white;
}
.text-primary {
  color: #215891;
}
.text-success {
  color: #67C23A;
}
.text-info {
  color: #2DB7F5;
}
.text-warning {
  color: #E6A23C;
}
.text-danger {
  color: #FF756F;
}
.text-gray {
  color: #c2c2c2;
}
.text-laxative {
  color: #B3EE3A;
}
.text-theme {
  color: #215891;
}
.bg-navy {
  background-color: #001F3F;
}
.bg-blue {
  background-color: #0074D9;
}
.bg-aqua {
  background-color: #7FDBFF;
}
.bg-aliceblue {
  background-color: aliceblue;
}
.bg-pink {
  background-color: pink;
}
.bg-azure {
  background-color: azure;
}
.bg-teal {
  background-color: #39CCCC;
}
.bg-olive {
  background-color: #3D9970;
}
.bg-green {
  background-color: #2ECC40;
}
.bg-lime {
  background-color: #01FF70;
}
.bg-yellow {
  background-color: #FFDC00;
}
.bg-pink {
  color: pink;
}
.bg-orange {
  background-color: #FF851B;
}
.bg-red {
  background-color: #FF4136;
}
.bg-fuchsia {
  background-color: #F012BE;
}
.bg-purple {
  background-color: #B10DC9;
}
.bg-maroon {
  background-color: #85144B;
}
.bg-white {
  background-color: #FFFFFF;
}
.bg-gray {
  background-color: #AAAAAA;
}
.bg-silver {
  background-color: #DDDDDD;
}
.bg-silver-white {
  background-color: #EEEEEE;
}
.bg-black {
  background-color: #111111;
}
.bg-111 {
  background-color: #111;
}
.bg-222 {
  background-color: #222;
}
.bg-333 {
  background-color: #333;
}
.bg-444 {
  background-color: #444;
}
.bg-555 {
  background-color: #555;
}
.bg-666 {
  background-color: #666;
}
.bg-777 {
  background-color: #777;
}
.bg-888 {
  background-color: #888;
}
.bg-999 {
  background-color: #999;
}
.bg-aaa {
  background-color: #aaa;
}
.bg-bbb {
  background-color: #bbb;
}
.bg-ccc {
  background-color: #ccc;
}
.bg-ddd {
  background-color: #ddd;
}
.bg-eee {
  background-color: #eee;
}
/* Colors */
.navy {
  color: #001F3F;
}
.blue {
  color: #0074D9;
}
.aqua {
  color: #7FDBFF;
}
.teal {
  color: #39CCCC;
}
.olive {
  color: #3D9970;
}
.green {
  color: #2ECC40;
}
.lime {
  color: #01FF70;
}
.yellow {
  color: #FFDC00;
}
.pink {
  color: pink;
}
.orange {
  color: #FF851B;
}
.red {
  color: #FF4136;
}
.fuchsia {
  color: #F012BE;
}
.purple {
  color: #B10DC9;
}
.maroon {
  color: #85144B;
}
.white {
  color: #FFFFFF;
}
.silver {
  color: #DDDDDD;
}
.gray {
  color: #AAAAAA;
}
.black {
  color: #111111;
}
.color-111 {
  color: #111;
}
.color-222 {
  color: #222;
}
.color-333 {
  color: #333;
}
.color-444 {
  color: #444;
}
.color-555 {
  color: #555;
}
.color-666 {
  color: #666;
}
.color-777 {
  color: #777;
}
.color-888 {
  color: #888;
}
.color-999 {
  color: #999;
}
.color-aaa {
  color: #aaa;
}
.color-bbb {
  color: #bbb;
}
.color-ccc {
  color: #ccc;
}
.color-ddd {
  color: #ddd;
}
.color-eee {
  color: #eee;
}
.color-text {
  color: #660E7A;
}
.color-doc {
  color: #295496;
}
.color-xls {
  color: #1E6C41;
}
.color-ppt {
  color: #D04324;
}
.color-pdf {
  color: #E40B0B;
}
.color-audio {
  color: #5bc0de;
}
.color-video {
  color: #5cb85c;
}
.color-image {
  color: #0074D9;
}
.color-archive {
  color: #4437f2;
}
.color-light-active {
  color: #ffc60c;
}
.color-light-inactive {
  color: #ccc;
}
.f80 {
  font-size: 80px !important;
}
.f79 {
  font-size: 79px !important;
}
.f78 {
  font-size: 78px !important;
}
.f77 {
  font-size: 77px !important;
}
.f76 {
  font-size: 76px !important;
}
.f75 {
  font-size: 75px !important;
}
.f74 {
  font-size: 74px !important;
}
.f73 {
  font-size: 73px !important;
}
.f72 {
  font-size: 72px !important;
}
.f71 {
  font-size: 71px !important;
}
.f70 {
  font-size: 70px !important;
}
.f69 {
  font-size: 69px !important;
}
.f68 {
  font-size: 68px !important;
}
.f67 {
  font-size: 67px !important;
}
.f66 {
  font-size: 66px !important;
}
.f65 {
  font-size: 65px !important;
}
.f64 {
  font-size: 64px !important;
}
.f63 {
  font-size: 63px !important;
}
.f62 {
  font-size: 62px !important;
}
.f61 {
  font-size: 61px !important;
}
.f60 {
  font-size: 60px !important;
}
.f59 {
  font-size: 59px !important;
}
.f58 {
  font-size: 58px !important;
}
.f57 {
  font-size: 57px !important;
}
.f56 {
  font-size: 56px !important;
}
.f55 {
  font-size: 55px !important;
}
.f54 {
  font-size: 54px !important;
}
.f53 {
  font-size: 53px !important;
}
.f52 {
  font-size: 52px !important;
}
.f51 {
  font-size: 51px !important;
}
.f50 {
  font-size: 50px !important;
}
.f49 {
  font-size: 49px !important;
}
.f48 {
  font-size: 48px !important;
}
.f47 {
  font-size: 47px !important;
}
.f46 {
  font-size: 46px !important;
}
.f45 {
  font-size: 45px !important;
}
.f44 {
  font-size: 44px !important;
}
.f43 {
  font-size: 43px !important;
}
.f42 {
  font-size: 42px !important;
}
.f41 {
  font-size: 41px !important;
}
.f40 {
  font-size: 40px !important;
}
.f39 {
  font-size: 39px !important;
}
.f38 {
  font-size: 38px !important;
}
.f37 {
  font-size: 37px !important;
}
.f36 {
  font-size: 36px !important;
}
.f35 {
  font-size: 35px !important;
}
.f34 {
  font-size: 34px !important;
}
.f33 {
  font-size: 33px !important;
}
.f32 {
  font-size: 32px !important;
}
.f31 {
  font-size: 31px !important;
}
.f30 {
  font-size: 30px !important;
}
.f29 {
  font-size: 29px !important;
}
.f28 {
  font-size: 28px !important;
}
.f27 {
  font-size: 27px !important;
}
.f26 {
  font-size: 26px !important;
}
.f25 {
  font-size: 25px !important;
}
.f24 {
  font-size: 24px !important;
}
.f23 {
  font-size: 23px !important;
}
.f22 {
  font-size: 22px !important;
}
.f21 {
  font-size: 21px !important;
}
.f20 {
  font-size: 20px !important;
}
.f19 {
  font-size: 19px !important;
}
.f18 {
  font-size: 18px !important;
}
.f17 {
  font-size: 17px !important;
}
.f16 {
  font-size: 16px !important;
}
.f15 {
  font-size: 15px !important;
}
.f14 {
  font-size: 14px !important;
}
.f13 {
  font-size: 13px !important;
}
.f12 {
  font-size: 12px !important;
}
.f11 {
  font-size: 11px !important;
}
.f10 {
  font-size: 10px !important;
}
.ln100 {
  line-height: 100px !important;
}
.ln99 {
  line-height: 99px !important;
}
.ln98 {
  line-height: 98px !important;
}
.ln97 {
  line-height: 97px !important;
}
.ln96 {
  line-height: 96px !important;
}
.ln95 {
  line-height: 95px !important;
}
.ln94 {
  line-height: 94px !important;
}
.ln93 {
  line-height: 93px !important;
}
.ln92 {
  line-height: 92px !important;
}
.ln91 {
  line-height: 91px !important;
}
.ln90 {
  line-height: 90px !important;
}
.ln89 {
  line-height: 89px !important;
}
.ln88 {
  line-height: 88px !important;
}
.ln87 {
  line-height: 87px !important;
}
.ln86 {
  line-height: 86px !important;
}
.ln85 {
  line-height: 85px !important;
}
.ln84 {
  line-height: 84px !important;
}
.ln83 {
  line-height: 83px !important;
}
.ln82 {
  line-height: 82px !important;
}
.ln81 {
  line-height: 81px !important;
}
.ln80 {
  line-height: 80px !important;
}
.ln79 {
  line-height: 79px !important;
}
.ln78 {
  line-height: 78px !important;
}
.ln77 {
  line-height: 77px !important;
}
.ln76 {
  line-height: 76px !important;
}
.ln75 {
  line-height: 75px !important;
}
.ln74 {
  line-height: 74px !important;
}
.ln73 {
  line-height: 73px !important;
}
.ln72 {
  line-height: 72px !important;
}
.ln71 {
  line-height: 71px !important;
}
.ln70 {
  line-height: 70px !important;
}
.ln69 {
  line-height: 69px !important;
}
.ln68 {
  line-height: 68px !important;
}
.ln67 {
  line-height: 67px !important;
}
.ln66 {
  line-height: 66px !important;
}
.ln65 {
  line-height: 65px !important;
}
.ln64 {
  line-height: 64px !important;
}
.ln63 {
  line-height: 63px !important;
}
.ln62 {
  line-height: 62px !important;
}
.ln61 {
  line-height: 61px !important;
}
.ln60 {
  line-height: 60px !important;
}
.ln59 {
  line-height: 59px !important;
}
.ln58 {
  line-height: 58px !important;
}
.ln57 {
  line-height: 57px !important;
}
.ln56 {
  line-height: 56px !important;
}
.ln55 {
  line-height: 55px !important;
}
.ln54 {
  line-height: 54px !important;
}
.ln53 {
  line-height: 53px !important;
}
.ln52 {
  line-height: 52px !important;
}
.ln51 {
  line-height: 51px !important;
}
.ln50 {
  line-height: 50px !important;
}
.ln49 {
  line-height: 49px !important;
}
.ln48 {
  line-height: 48px !important;
}
.ln47 {
  line-height: 47px !important;
}
.ln46 {
  line-height: 46px !important;
}
.ln45 {
  line-height: 45px !important;
}
.ln44 {
  line-height: 44px !important;
}
.ln43 {
  line-height: 43px !important;
}
.ln42 {
  line-height: 42px !important;
}
.ln41 {
  line-height: 41px !important;
}
.ln40 {
  line-height: 40px !important;
}
.ln39 {
  line-height: 39px !important;
}
.ln38 {
  line-height: 38px !important;
}
.ln37 {
  line-height: 37px !important;
}
.ln36 {
  line-height: 36px !important;
}
.ln35 {
  line-height: 35px !important;
}
.ln34 {
  line-height: 34px !important;
}
.ln33 {
  line-height: 33px !important;
}
.ln32 {
  line-height: 32px !important;
}
.ln31 {
  line-height: 31px !important;
}
.ln30 {
  line-height: 30px !important;
}
.ln29 {
  line-height: 29px !important;
}
.ln28 {
  line-height: 28px !important;
}
.ln27 {
  line-height: 27px !important;
}
.ln26 {
  line-height: 26px !important;
}
.ln25 {
  line-height: 25px !important;
}
.ln24 {
  line-height: 24px !important;
}
.ln23 {
  line-height: 23px !important;
}
.ln22 {
  line-height: 22px !important;
}
.ln21 {
  line-height: 21px !important;
}
.ln20 {
  line-height: 20px !important;
}
.ln19 {
  line-height: 19px !important;
}
.ln18 {
  line-height: 18px !important;
}
.ln17 {
  line-height: 17px !important;
}
.ln16 {
  line-height: 16px !important;
}
.ln15 {
  line-height: 15px !important;
}
.ln14 {
  line-height: 14px !important;
}
.ln13 {
  line-height: 13px !important;
}
.ln12 {
  line-height: 12px !important;
}
.ln11 {
  line-height: 11px !important;
}
.ln10 {
  line-height: 10px !important;
}
.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
/**
全局常用样式定义
使用ml、pt等缩写表示常用的布局方式，数字表示像素值
比如ml10表示margin-left:10px;
 */
.wp20 {
  width: 20%;
}
.wp25 {
  width: 25%;
}
.wp33 {
  width: 33%;
}
.wp100 {
  width: 100%;
}
.wp50 {
  width: 50%;
}
.hp100 {
  height: 100%;
}
.hp50 {
  height: 50%;
}
.m200 {
  margin: 200px;
}
.mt200 {
  margin-top: 200px;
}
.mr200 {
  margin-right: 200px;
}
.mb200 {
  margin-bottom: 200px;
}
.ml200 {
  margin-left: 200px;
}
.mv200 {
  margin-top: 200px;
  margin-bottom: 200px;
}
.mh200 {
  margin-left: 200px;
  margin-right: 200px;
}
.m195 {
  margin: 195px;
}
.mt195 {
  margin-top: 195px;
}
.mr195 {
  margin-right: 195px;
}
.mb195 {
  margin-bottom: 195px;
}
.ml195 {
  margin-left: 195px;
}
.mv195 {
  margin-top: 195px;
  margin-bottom: 195px;
}
.mh195 {
  margin-left: 195px;
  margin-right: 195px;
}
.m190 {
  margin: 190px;
}
.mt190 {
  margin-top: 190px;
}
.mr190 {
  margin-right: 190px;
}
.mb190 {
  margin-bottom: 190px;
}
.ml190 {
  margin-left: 190px;
}
.mv190 {
  margin-top: 190px;
  margin-bottom: 190px;
}
.mh190 {
  margin-left: 190px;
  margin-right: 190px;
}
.m185 {
  margin: 185px;
}
.mt185 {
  margin-top: 185px;
}
.mr185 {
  margin-right: 185px;
}
.mb185 {
  margin-bottom: 185px;
}
.ml185 {
  margin-left: 185px;
}
.mv185 {
  margin-top: 185px;
  margin-bottom: 185px;
}
.mh185 {
  margin-left: 185px;
  margin-right: 185px;
}
.m180 {
  margin: 180px;
}
.mt180 {
  margin-top: 180px;
}
.mr180 {
  margin-right: 180px;
}
.mb180 {
  margin-bottom: 180px;
}
.ml180 {
  margin-left: 180px;
}
.mv180 {
  margin-top: 180px;
  margin-bottom: 180px;
}
.mh180 {
  margin-left: 180px;
  margin-right: 180px;
}
.m175 {
  margin: 175px;
}
.mt175 {
  margin-top: 175px;
}
.mr175 {
  margin-right: 175px;
}
.mb175 {
  margin-bottom: 175px;
}
.ml175 {
  margin-left: 175px;
}
.mv175 {
  margin-top: 175px;
  margin-bottom: 175px;
}
.mh175 {
  margin-left: 175px;
  margin-right: 175px;
}
.m170 {
  margin: 170px;
}
.mt170 {
  margin-top: 170px;
}
.mr170 {
  margin-right: 170px;
}
.mb170 {
  margin-bottom: 170px;
}
.ml170 {
  margin-left: 170px;
}
.mv170 {
  margin-top: 170px;
  margin-bottom: 170px;
}
.mh170 {
  margin-left: 170px;
  margin-right: 170px;
}
.m165 {
  margin: 165px;
}
.mt165 {
  margin-top: 165px;
}
.mr165 {
  margin-right: 165px;
}
.mb165 {
  margin-bottom: 165px;
}
.ml165 {
  margin-left: 165px;
}
.mv165 {
  margin-top: 165px;
  margin-bottom: 165px;
}
.mh165 {
  margin-left: 165px;
  margin-right: 165px;
}
.m160 {
  margin: 160px;
}
.mt160 {
  margin-top: 160px;
}
.mr160 {
  margin-right: 160px;
}
.mb160 {
  margin-bottom: 160px;
}
.ml160 {
  margin-left: 160px;
}
.mv160 {
  margin-top: 160px;
  margin-bottom: 160px;
}
.mh160 {
  margin-left: 160px;
  margin-right: 160px;
}
.m155 {
  margin: 155px;
}
.mt155 {
  margin-top: 155px;
}
.mr155 {
  margin-right: 155px;
}
.mb155 {
  margin-bottom: 155px;
}
.ml155 {
  margin-left: 155px;
}
.mv155 {
  margin-top: 155px;
  margin-bottom: 155px;
}
.mh155 {
  margin-left: 155px;
  margin-right: 155px;
}
.m150 {
  margin: 150px;
}
.mt150 {
  margin-top: 150px;
}
.mr150 {
  margin-right: 150px;
}
.mb150 {
  margin-bottom: 150px;
}
.ml150 {
  margin-left: 150px;
}
.mv150 {
  margin-top: 150px;
  margin-bottom: 150px;
}
.mh150 {
  margin-left: 150px;
  margin-right: 150px;
}
.m145 {
  margin: 145px;
}
.mt145 {
  margin-top: 145px;
}
.mr145 {
  margin-right: 145px;
}
.mb145 {
  margin-bottom: 145px;
}
.ml145 {
  margin-left: 145px;
}
.mv145 {
  margin-top: 145px;
  margin-bottom: 145px;
}
.mh145 {
  margin-left: 145px;
  margin-right: 145px;
}
.m140 {
  margin: 140px;
}
.mt140 {
  margin-top: 140px;
}
.mr140 {
  margin-right: 140px;
}
.mb140 {
  margin-bottom: 140px;
}
.ml140 {
  margin-left: 140px;
}
.mv140 {
  margin-top: 140px;
  margin-bottom: 140px;
}
.mh140 {
  margin-left: 140px;
  margin-right: 140px;
}
.m135 {
  margin: 135px;
}
.mt135 {
  margin-top: 135px;
}
.mr135 {
  margin-right: 135px;
}
.mb135 {
  margin-bottom: 135px;
}
.ml135 {
  margin-left: 135px;
}
.mv135 {
  margin-top: 135px;
  margin-bottom: 135px;
}
.mh135 {
  margin-left: 135px;
  margin-right: 135px;
}
.m130 {
  margin: 130px;
}
.mt130 {
  margin-top: 130px;
}
.mr130 {
  margin-right: 130px;
}
.mb130 {
  margin-bottom: 130px;
}
.ml130 {
  margin-left: 130px;
}
.mv130 {
  margin-top: 130px;
  margin-bottom: 130px;
}
.mh130 {
  margin-left: 130px;
  margin-right: 130px;
}
.m125 {
  margin: 125px;
}
.mt125 {
  margin-top: 125px;
}
.mr125 {
  margin-right: 125px;
}
.mb125 {
  margin-bottom: 125px;
}
.ml125 {
  margin-left: 125px;
}
.mv125 {
  margin-top: 125px;
  margin-bottom: 125px;
}
.mh125 {
  margin-left: 125px;
  margin-right: 125px;
}
.m120 {
  margin: 120px;
}
.mt120 {
  margin-top: 120px;
}
.mr120 {
  margin-right: 120px;
}
.mb120 {
  margin-bottom: 120px;
}
.ml120 {
  margin-left: 120px;
}
.mv120 {
  margin-top: 120px;
  margin-bottom: 120px;
}
.mh120 {
  margin-left: 120px;
  margin-right: 120px;
}
.m115 {
  margin: 115px;
}
.mt115 {
  margin-top: 115px;
}
.mr115 {
  margin-right: 115px;
}
.mb115 {
  margin-bottom: 115px;
}
.ml115 {
  margin-left: 115px;
}
.mv115 {
  margin-top: 115px;
  margin-bottom: 115px;
}
.mh115 {
  margin-left: 115px;
  margin-right: 115px;
}
.m110 {
  margin: 110px;
}
.mt110 {
  margin-top: 110px;
}
.mr110 {
  margin-right: 110px;
}
.mb110 {
  margin-bottom: 110px;
}
.ml110 {
  margin-left: 110px;
}
.mv110 {
  margin-top: 110px;
  margin-bottom: 110px;
}
.mh110 {
  margin-left: 110px;
  margin-right: 110px;
}
.m105 {
  margin: 105px;
}
.mt105 {
  margin-top: 105px;
}
.mr105 {
  margin-right: 105px;
}
.mb105 {
  margin-bottom: 105px;
}
.ml105 {
  margin-left: 105px;
}
.mv105 {
  margin-top: 105px;
  margin-bottom: 105px;
}
.mh105 {
  margin-left: 105px;
  margin-right: 105px;
}
.m100 {
  margin: 100px;
}
.mt100 {
  margin-top: 100px;
}
.mr100 {
  margin-right: 100px;
}
.mb100 {
  margin-bottom: 100px;
}
.ml100 {
  margin-left: 100px;
}
.mv100 {
  margin-top: 100px;
  margin-bottom: 100px;
}
.mh100 {
  margin-left: 100px;
  margin-right: 100px;
}
.m95 {
  margin: 95px;
}
.mt95 {
  margin-top: 95px;
}
.mr95 {
  margin-right: 95px;
}
.mb95 {
  margin-bottom: 95px;
}
.ml95 {
  margin-left: 95px;
}
.mv95 {
  margin-top: 95px;
  margin-bottom: 95px;
}
.mh95 {
  margin-left: 95px;
  margin-right: 95px;
}
.m90 {
  margin: 90px;
}
.mt90 {
  margin-top: 90px;
}
.mr90 {
  margin-right: 90px;
}
.mb90 {
  margin-bottom: 90px;
}
.ml90 {
  margin-left: 90px;
}
.mv90 {
  margin-top: 90px;
  margin-bottom: 90px;
}
.mh90 {
  margin-left: 90px;
  margin-right: 90px;
}
.m85 {
  margin: 85px;
}
.mt85 {
  margin-top: 85px;
}
.mr85 {
  margin-right: 85px;
}
.mb85 {
  margin-bottom: 85px;
}
.ml85 {
  margin-left: 85px;
}
.mv85 {
  margin-top: 85px;
  margin-bottom: 85px;
}
.mh85 {
  margin-left: 85px;
  margin-right: 85px;
}
.m80 {
  margin: 80px;
}
.mt80 {
  margin-top: 80px;
}
.mr80 {
  margin-right: 80px;
}
.mb80 {
  margin-bottom: 80px;
}
.ml80 {
  margin-left: 80px;
}
.mv80 {
  margin-top: 80px;
  margin-bottom: 80px;
}
.mh80 {
  margin-left: 80px;
  margin-right: 80px;
}
.m75 {
  margin: 75px;
}
.mt75 {
  margin-top: 75px;
}
.mr75 {
  margin-right: 75px;
}
.mb75 {
  margin-bottom: 75px;
}
.ml75 {
  margin-left: 75px;
}
.mv75 {
  margin-top: 75px;
  margin-bottom: 75px;
}
.mh75 {
  margin-left: 75px;
  margin-right: 75px;
}
.m70 {
  margin: 70px;
}
.mt70 {
  margin-top: 70px;
}
.mr70 {
  margin-right: 70px;
}
.mb70 {
  margin-bottom: 70px;
}
.ml70 {
  margin-left: 70px;
}
.mv70 {
  margin-top: 70px;
  margin-bottom: 70px;
}
.mh70 {
  margin-left: 70px;
  margin-right: 70px;
}
.m65 {
  margin: 65px;
}
.mt65 {
  margin-top: 65px;
}
.mr65 {
  margin-right: 65px;
}
.mb65 {
  margin-bottom: 65px;
}
.ml65 {
  margin-left: 65px;
}
.mv65 {
  margin-top: 65px;
  margin-bottom: 65px;
}
.mh65 {
  margin-left: 65px;
  margin-right: 65px;
}
.m60 {
  margin: 60px;
}
.mt60 {
  margin-top: 60px;
}
.mr60 {
  margin-right: 60px;
}
.mb60 {
  margin-bottom: 60px;
}
.ml60 {
  margin-left: 60px;
}
.mv60 {
  margin-top: 60px;
  margin-bottom: 60px;
}
.mh60 {
  margin-left: 60px;
  margin-right: 60px;
}
.m55 {
  margin: 55px;
}
.mt55 {
  margin-top: 55px;
}
.mr55 {
  margin-right: 55px;
}
.mb55 {
  margin-bottom: 55px;
}
.ml55 {
  margin-left: 55px;
}
.mv55 {
  margin-top: 55px;
  margin-bottom: 55px;
}
.mh55 {
  margin-left: 55px;
  margin-right: 55px;
}
.m50 {
  margin: 50px;
}
.mt50 {
  margin-top: 50px;
}
.mr50 {
  margin-right: 50px;
}
.mb50 {
  margin-bottom: 50px;
}
.ml50 {
  margin-left: 50px;
}
.mv50 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.mh50 {
  margin-left: 50px;
  margin-right: 50px;
}
.m45 {
  margin: 45px;
}
.mt45 {
  margin-top: 45px;
}
.mr45 {
  margin-right: 45px;
}
.mb45 {
  margin-bottom: 45px;
}
.ml45 {
  margin-left: 45px;
}
.mv45 {
  margin-top: 45px;
  margin-bottom: 45px;
}
.mh45 {
  margin-left: 45px;
  margin-right: 45px;
}
.m40 {
  margin: 40px;
}
.mt40 {
  margin-top: 40px;
}
.mr40 {
  margin-right: 40px;
}
.mb40 {
  margin-bottom: 40px;
}
.ml40 {
  margin-left: 40px;
}
.mv40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.mh40 {
  margin-left: 40px;
  margin-right: 40px;
}
.m35 {
  margin: 35px;
}
.mt35 {
  margin-top: 35px;
}
.mr35 {
  margin-right: 35px;
}
.mb35 {
  margin-bottom: 35px;
}
.ml35 {
  margin-left: 35px;
}
.mv35 {
  margin-top: 35px;
  margin-bottom: 35px;
}
.mh35 {
  margin-left: 35px;
  margin-right: 35px;
}
.m30 {
  margin: 30px;
}
.mt30 {
  margin-top: 30px;
}
.mr30 {
  margin-right: 30px;
}
.mb30 {
  margin-bottom: 30px;
}
.ml30 {
  margin-left: 30px;
}
.mv30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.mh30 {
  margin-left: 30px;
  margin-right: 30px;
}
.m25 {
  margin: 25px;
}
.mt25 {
  margin-top: 25px;
}
.mr25 {
  margin-right: 25px;
}
.mb25 {
  margin-bottom: 25px;
}
.ml25 {
  margin-left: 25px;
}
.mv25 {
  margin-top: 25px;
  margin-bottom: 25px;
}
.mh25 {
  margin-left: 25px;
  margin-right: 25px;
}
.m20 {
  margin: 20px;
}
.mt20 {
  margin-top: 20px;
}
.mr20 {
  margin-right: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.ml20 {
  margin-left: 20px;
}
.mv20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.mh20 {
  margin-left: 20px;
  margin-right: 20px;
}
.m19 {
  margin: 19px;
}
.mt19 {
  margin-top: 19px;
}
.mr19 {
  margin-right: 19px;
}
.mb19 {
  margin-bottom: 19px;
}
.ml19 {
  margin-left: 19px;
}
.mv19 {
  margin-top: 19px;
  margin-bottom: 19px;
}
.mh19 {
  margin-left: 19px;
  margin-right: 19px;
}
.m18 {
  margin: 18px;
}
.mt18 {
  margin-top: 18px;
}
.mr18 {
  margin-right: 18px;
}
.mb18 {
  margin-bottom: 18px;
}
.ml18 {
  margin-left: 18px;
}
.mv18 {
  margin-top: 18px;
  margin-bottom: 18px;
}
.mh18 {
  margin-left: 18px;
  margin-right: 18px;
}
.m17 {
  margin: 17px;
}
.mt17 {
  margin-top: 17px;
}
.mr17 {
  margin-right: 17px;
}
.mb17 {
  margin-bottom: 17px;
}
.ml17 {
  margin-left: 17px;
}
.mv17 {
  margin-top: 17px;
  margin-bottom: 17px;
}
.mh17 {
  margin-left: 17px;
  margin-right: 17px;
}
.m16 {
  margin: 16px;
}
.mt16 {
  margin-top: 16px;
}
.mr16 {
  margin-right: 16px;
}
.mb16 {
  margin-bottom: 16px;
}
.ml16 {
  margin-left: 16px;
}
.mv16 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mh16 {
  margin-left: 16px;
  margin-right: 16px;
}
.m15 {
  margin: 15px;
}
.mt15 {
  margin-top: 15px;
}
.mr15 {
  margin-right: 15px;
}
.mb15 {
  margin-bottom: 15px;
}
.ml15 {
  margin-left: 15px;
}
.mv15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.mh15 {
  margin-left: 15px;
  margin-right: 15px;
}
.m14 {
  margin: 14px;
}
.mt14 {
  margin-top: 14px;
}
.mr14 {
  margin-right: 14px;
}
.mb14 {
  margin-bottom: 14px;
}
.ml14 {
  margin-left: 14px;
}
.mv14 {
  margin-top: 14px;
  margin-bottom: 14px;
}
.mh14 {
  margin-left: 14px;
  margin-right: 14px;
}
.m13 {
  margin: 13px;
}
.mt13 {
  margin-top: 13px;
}
.mr13 {
  margin-right: 13px;
}
.mb13 {
  margin-bottom: 13px;
}
.ml13 {
  margin-left: 13px;
}
.mv13 {
  margin-top: 13px;
  margin-bottom: 13px;
}
.mh13 {
  margin-left: 13px;
  margin-right: 13px;
}
.m12 {
  margin: 12px;
}
.mt12 {
  margin-top: 12px;
}
.mr12 {
  margin-right: 12px;
}
.mb12 {
  margin-bottom: 12px;
}
.ml12 {
  margin-left: 12px;
}
.mv12 {
  margin-top: 12px;
  margin-bottom: 12px;
}
.mh12 {
  margin-left: 12px;
  margin-right: 12px;
}
.m11 {
  margin: 11px;
}
.mt11 {
  margin-top: 11px;
}
.mr11 {
  margin-right: 11px;
}
.mb11 {
  margin-bottom: 11px;
}
.ml11 {
  margin-left: 11px;
}
.mv11 {
  margin-top: 11px;
  margin-bottom: 11px;
}
.mh11 {
  margin-left: 11px;
  margin-right: 11px;
}
.m10 {
  margin: 10px;
}
.mt10 {
  margin-top: 10px;
}
.mr10 {
  margin-right: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.ml10 {
  margin-left: 10px;
}
.mv10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.mh10 {
  margin-left: 10px;
  margin-right: 10px;
}
.m9 {
  margin: 9px;
}
.mt9 {
  margin-top: 9px;
}
.mr9 {
  margin-right: 9px;
}
.mb9 {
  margin-bottom: 9px;
}
.ml9 {
  margin-left: 9px;
}
.mv9 {
  margin-top: 9px;
  margin-bottom: 9px;
}
.mh9 {
  margin-left: 9px;
  margin-right: 9px;
}
.m8 {
  margin: 8px;
}
.mt8 {
  margin-top: 8px;
}
.mr8 {
  margin-right: 8px;
}
.mb8 {
  margin-bottom: 8px;
}
.ml8 {
  margin-left: 8px;
}
.mv8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.mh8 {
  margin-left: 8px;
  margin-right: 8px;
}
.m7 {
  margin: 7px;
}
.mt7 {
  margin-top: 7px;
}
.mr7 {
  margin-right: 7px;
}
.mb7 {
  margin-bottom: 7px;
}
.ml7 {
  margin-left: 7px;
}
.mv7 {
  margin-top: 7px;
  margin-bottom: 7px;
}
.mh7 {
  margin-left: 7px;
  margin-right: 7px;
}
.m6 {
  margin: 6px;
}
.mt6 {
  margin-top: 6px;
}
.mr6 {
  margin-right: 6px;
}
.mb6 {
  margin-bottom: 6px;
}
.ml6 {
  margin-left: 6px;
}
.mv6 {
  margin-top: 6px;
  margin-bottom: 6px;
}
.mh6 {
  margin-left: 6px;
  margin-right: 6px;
}
.m5 {
  margin: 5px;
}
.mt5 {
  margin-top: 5px;
}
.mr5 {
  margin-right: 5px;
}
.mb5 {
  margin-bottom: 5px;
}
.ml5 {
  margin-left: 5px;
}
.mv5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.mh5 {
  margin-left: 5px;
  margin-right: 5px;
}
.m4 {
  margin: 4px;
}
.mt4 {
  margin-top: 4px;
}
.mr4 {
  margin-right: 4px;
}
.mb4 {
  margin-bottom: 4px;
}
.ml4 {
  margin-left: 4px;
}
.mv4 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.mh4 {
  margin-left: 4px;
  margin-right: 4px;
}
.m3 {
  margin: 3px;
}
.mt3 {
  margin-top: 3px;
}
.mr3 {
  margin-right: 3px;
}
.mb3 {
  margin-bottom: 3px;
}
.ml3 {
  margin-left: 3px;
}
.mv3 {
  margin-top: 3px;
  margin-bottom: 3px;
}
.mh3 {
  margin-left: 3px;
  margin-right: 3px;
}
.m2 {
  margin: 2px;
}
.mt2 {
  margin-top: 2px;
}
.mr2 {
  margin-right: 2px;
}
.mb2 {
  margin-bottom: 2px;
}
.ml2 {
  margin-left: 2px;
}
.mv2 {
  margin-top: 2px;
  margin-bottom: 2px;
}
.mh2 {
  margin-left: 2px;
  margin-right: 2px;
}
.m1 {
  margin: 1px;
}
.mt1 {
  margin-top: 1px;
}
.mr1 {
  margin-right: 1px;
}
.mb1 {
  margin-bottom: 1px;
}
.ml1 {
  margin-left: 1px;
}
.mv1 {
  margin-top: 1px;
  margin-bottom: 1px;
}
.mh1 {
  margin-left: 1px;
  margin-right: 1px;
}
.m0 {
  margin: 0px;
}
.mt0 {
  margin-top: 0px;
}
.mr0 {
  margin-right: 0px;
}
.mb0 {
  margin-bottom: 0px;
}
.ml0 {
  margin-left: 0px;
}
.mv0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.mh0 {
  margin-left: 0px;
  margin-right: 0px;
}
.p200 {
  padding: 200px;
}
.pt200 {
  padding-top: 200px;
}
.pr200 {
  padding-right: 200px;
}
.pb200 {
  padding-bottom: 200px;
}
.pl200 {
  padding-left: 200px;
}
.pv200 {
  padding-top: 200px;
  padding-bottom: 200px;
}
.ph200 {
  padding-left: 200px;
  padding-right: 200px;
}
.p195 {
  padding: 195px;
}
.pt195 {
  padding-top: 195px;
}
.pr195 {
  padding-right: 195px;
}
.pb195 {
  padding-bottom: 195px;
}
.pl195 {
  padding-left: 195px;
}
.pv195 {
  padding-top: 195px;
  padding-bottom: 195px;
}
.ph195 {
  padding-left: 195px;
  padding-right: 195px;
}
.p190 {
  padding: 190px;
}
.pt190 {
  padding-top: 190px;
}
.pr190 {
  padding-right: 190px;
}
.pb190 {
  padding-bottom: 190px;
}
.pl190 {
  padding-left: 190px;
}
.pv190 {
  padding-top: 190px;
  padding-bottom: 190px;
}
.ph190 {
  padding-left: 190px;
  padding-right: 190px;
}
.p185 {
  padding: 185px;
}
.pt185 {
  padding-top: 185px;
}
.pr185 {
  padding-right: 185px;
}
.pb185 {
  padding-bottom: 185px;
}
.pl185 {
  padding-left: 185px;
}
.pv185 {
  padding-top: 185px;
  padding-bottom: 185px;
}
.ph185 {
  padding-left: 185px;
  padding-right: 185px;
}
.p180 {
  padding: 180px;
}
.pt180 {
  padding-top: 180px;
}
.pr180 {
  padding-right: 180px;
}
.pb180 {
  padding-bottom: 180px;
}
.pl180 {
  padding-left: 180px;
}
.pv180 {
  padding-top: 180px;
  padding-bottom: 180px;
}
.ph180 {
  padding-left: 180px;
  padding-right: 180px;
}
.p175 {
  padding: 175px;
}
.pt175 {
  padding-top: 175px;
}
.pr175 {
  padding-right: 175px;
}
.pb175 {
  padding-bottom: 175px;
}
.pl175 {
  padding-left: 175px;
}
.pv175 {
  padding-top: 175px;
  padding-bottom: 175px;
}
.ph175 {
  padding-left: 175px;
  padding-right: 175px;
}
.p170 {
  padding: 170px;
}
.pt170 {
  padding-top: 170px;
}
.pr170 {
  padding-right: 170px;
}
.pb170 {
  padding-bottom: 170px;
}
.pl170 {
  padding-left: 170px;
}
.pv170 {
  padding-top: 170px;
  padding-bottom: 170px;
}
.ph170 {
  padding-left: 170px;
  padding-right: 170px;
}
.p165 {
  padding: 165px;
}
.pt165 {
  padding-top: 165px;
}
.pr165 {
  padding-right: 165px;
}
.pb165 {
  padding-bottom: 165px;
}
.pl165 {
  padding-left: 165px;
}
.pv165 {
  padding-top: 165px;
  padding-bottom: 165px;
}
.ph165 {
  padding-left: 165px;
  padding-right: 165px;
}
.p160 {
  padding: 160px;
}
.pt160 {
  padding-top: 160px;
}
.pr160 {
  padding-right: 160px;
}
.pb160 {
  padding-bottom: 160px;
}
.pl160 {
  padding-left: 160px;
}
.pv160 {
  padding-top: 160px;
  padding-bottom: 160px;
}
.ph160 {
  padding-left: 160px;
  padding-right: 160px;
}
.p155 {
  padding: 155px;
}
.pt155 {
  padding-top: 155px;
}
.pr155 {
  padding-right: 155px;
}
.pb155 {
  padding-bottom: 155px;
}
.pl155 {
  padding-left: 155px;
}
.pv155 {
  padding-top: 155px;
  padding-bottom: 155px;
}
.ph155 {
  padding-left: 155px;
  padding-right: 155px;
}
.p150 {
  padding: 150px;
}
.pt150 {
  padding-top: 150px;
}
.pr150 {
  padding-right: 150px;
}
.pb150 {
  padding-bottom: 150px;
}
.pl150 {
  padding-left: 150px;
}
.pv150 {
  padding-top: 150px;
  padding-bottom: 150px;
}
.ph150 {
  padding-left: 150px;
  padding-right: 150px;
}
.p145 {
  padding: 145px;
}
.pt145 {
  padding-top: 145px;
}
.pr145 {
  padding-right: 145px;
}
.pb145 {
  padding-bottom: 145px;
}
.pl145 {
  padding-left: 145px;
}
.pv145 {
  padding-top: 145px;
  padding-bottom: 145px;
}
.ph145 {
  padding-left: 145px;
  padding-right: 145px;
}
.p140 {
  padding: 140px;
}
.pt140 {
  padding-top: 140px;
}
.pr140 {
  padding-right: 140px;
}
.pb140 {
  padding-bottom: 140px;
}
.pl140 {
  padding-left: 140px;
}
.pv140 {
  padding-top: 140px;
  padding-bottom: 140px;
}
.ph140 {
  padding-left: 140px;
  padding-right: 140px;
}
.p135 {
  padding: 135px;
}
.pt135 {
  padding-top: 135px;
}
.pr135 {
  padding-right: 135px;
}
.pb135 {
  padding-bottom: 135px;
}
.pl135 {
  padding-left: 135px;
}
.pv135 {
  padding-top: 135px;
  padding-bottom: 135px;
}
.ph135 {
  padding-left: 135px;
  padding-right: 135px;
}
.p130 {
  padding: 130px;
}
.pt130 {
  padding-top: 130px;
}
.pr130 {
  padding-right: 130px;
}
.pb130 {
  padding-bottom: 130px;
}
.pl130 {
  padding-left: 130px;
}
.pv130 {
  padding-top: 130px;
  padding-bottom: 130px;
}
.ph130 {
  padding-left: 130px;
  padding-right: 130px;
}
.p125 {
  padding: 125px;
}
.pt125 {
  padding-top: 125px;
}
.pr125 {
  padding-right: 125px;
}
.pb125 {
  padding-bottom: 125px;
}
.pl125 {
  padding-left: 125px;
}
.pv125 {
  padding-top: 125px;
  padding-bottom: 125px;
}
.ph125 {
  padding-left: 125px;
  padding-right: 125px;
}
.p120 {
  padding: 120px;
}
.pt120 {
  padding-top: 120px;
}
.pr120 {
  padding-right: 120px;
}
.pb120 {
  padding-bottom: 120px;
}
.pl120 {
  padding-left: 120px;
}
.pv120 {
  padding-top: 120px;
  padding-bottom: 120px;
}
.ph120 {
  padding-left: 120px;
  padding-right: 120px;
}
.p115 {
  padding: 115px;
}
.pt115 {
  padding-top: 115px;
}
.pr115 {
  padding-right: 115px;
}
.pb115 {
  padding-bottom: 115px;
}
.pl115 {
  padding-left: 115px;
}
.pv115 {
  padding-top: 115px;
  padding-bottom: 115px;
}
.ph115 {
  padding-left: 115px;
  padding-right: 115px;
}
.p110 {
  padding: 110px;
}
.pt110 {
  padding-top: 110px;
}
.pr110 {
  padding-right: 110px;
}
.pb110 {
  padding-bottom: 110px;
}
.pl110 {
  padding-left: 110px;
}
.pv110 {
  padding-top: 110px;
  padding-bottom: 110px;
}
.ph110 {
  padding-left: 110px;
  padding-right: 110px;
}
.p105 {
  padding: 105px;
}
.pt105 {
  padding-top: 105px;
}
.pr105 {
  padding-right: 105px;
}
.pb105 {
  padding-bottom: 105px;
}
.pl105 {
  padding-left: 105px;
}
.pv105 {
  padding-top: 105px;
  padding-bottom: 105px;
}
.ph105 {
  padding-left: 105px;
  padding-right: 105px;
}
.p100 {
  padding: 100px;
}
.pt100 {
  padding-top: 100px;
}
.pr100 {
  padding-right: 100px;
}
.pb100 {
  padding-bottom: 100px;
}
.pl100 {
  padding-left: 100px;
}
.pv100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.ph100 {
  padding-left: 100px;
  padding-right: 100px;
}
.p95 {
  padding: 95px;
}
.pt95 {
  padding-top: 95px;
}
.pr95 {
  padding-right: 95px;
}
.pb95 {
  padding-bottom: 95px;
}
.pl95 {
  padding-left: 95px;
}
.pv95 {
  padding-top: 95px;
  padding-bottom: 95px;
}
.ph95 {
  padding-left: 95px;
  padding-right: 95px;
}
.p90 {
  padding: 90px;
}
.pt90 {
  padding-top: 90px;
}
.pr90 {
  padding-right: 90px;
}
.pb90 {
  padding-bottom: 90px;
}
.pl90 {
  padding-left: 90px;
}
.pv90 {
  padding-top: 90px;
  padding-bottom: 90px;
}
.ph90 {
  padding-left: 90px;
  padding-right: 90px;
}
.p85 {
  padding: 85px;
}
.pt85 {
  padding-top: 85px;
}
.pr85 {
  padding-right: 85px;
}
.pb85 {
  padding-bottom: 85px;
}
.pl85 {
  padding-left: 85px;
}
.pv85 {
  padding-top: 85px;
  padding-bottom: 85px;
}
.ph85 {
  padding-left: 85px;
  padding-right: 85px;
}
.p80 {
  padding: 80px;
}
.pt80 {
  padding-top: 80px;
}
.pr80 {
  padding-right: 80px;
}
.pb80 {
  padding-bottom: 80px;
}
.pl80 {
  padding-left: 80px;
}
.pv80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
.ph80 {
  padding-left: 80px;
  padding-right: 80px;
}
.p75 {
  padding: 75px;
}
.pt75 {
  padding-top: 75px;
}
.pr75 {
  padding-right: 75px;
}
.pb75 {
  padding-bottom: 75px;
}
.pl75 {
  padding-left: 75px;
}
.pv75 {
  padding-top: 75px;
  padding-bottom: 75px;
}
.ph75 {
  padding-left: 75px;
  padding-right: 75px;
}
.p70 {
  padding: 70px;
}
.pt70 {
  padding-top: 70px;
}
.pr70 {
  padding-right: 70px;
}
.pb70 {
  padding-bottom: 70px;
}
.pl70 {
  padding-left: 70px;
}
.pv70 {
  padding-top: 70px;
  padding-bottom: 70px;
}
.ph70 {
  padding-left: 70px;
  padding-right: 70px;
}
.p65 {
  padding: 65px;
}
.pt65 {
  padding-top: 65px;
}
.pr65 {
  padding-right: 65px;
}
.pb65 {
  padding-bottom: 65px;
}
.pl65 {
  padding-left: 65px;
}
.pv65 {
  padding-top: 65px;
  padding-bottom: 65px;
}
.ph65 {
  padding-left: 65px;
  padding-right: 65px;
}
.p60 {
  padding: 60px;
}
.pt60 {
  padding-top: 60px;
}
.pr60 {
  padding-right: 60px;
}
.pb60 {
  padding-bottom: 60px;
}
.pl60 {
  padding-left: 60px;
}
.pv60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.ph60 {
  padding-left: 60px;
  padding-right: 60px;
}
.p55 {
  padding: 55px;
}
.pt55 {
  padding-top: 55px;
}
.pr55 {
  padding-right: 55px;
}
.pb55 {
  padding-bottom: 55px;
}
.pl55 {
  padding-left: 55px;
}
.pv55 {
  padding-top: 55px;
  padding-bottom: 55px;
}
.ph55 {
  padding-left: 55px;
  padding-right: 55px;
}
.p50 {
  padding: 50px;
}
.pt50 {
  padding-top: 50px;
}
.pr50 {
  padding-right: 50px;
}
.pb50 {
  padding-bottom: 50px;
}
.pl50 {
  padding-left: 50px;
}
.pv50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.ph50 {
  padding-left: 50px;
  padding-right: 50px;
}
.p45 {
  padding: 45px;
}
.pt45 {
  padding-top: 45px;
}
.pr45 {
  padding-right: 45px;
}
.pb45 {
  padding-bottom: 45px;
}
.pl45 {
  padding-left: 45px;
}
.pv45 {
  padding-top: 45px;
  padding-bottom: 45px;
}
.ph45 {
  padding-left: 45px;
  padding-right: 45px;
}
.p40 {
  padding: 40px;
}
.pt40 {
  padding-top: 40px;
}
.pr40 {
  padding-right: 40px;
}
.pb40 {
  padding-bottom: 40px;
}
.pl40 {
  padding-left: 40px;
}
.pv40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.ph40 {
  padding-left: 40px;
  padding-right: 40px;
}
.p35 {
  padding: 35px;
}
.pt35 {
  padding-top: 35px;
}
.pr35 {
  padding-right: 35px;
}
.pb35 {
  padding-bottom: 35px;
}
.pl35 {
  padding-left: 35px;
}
.pv35 {
  padding-top: 35px;
  padding-bottom: 35px;
}
.ph35 {
  padding-left: 35px;
  padding-right: 35px;
}
.p30 {
  padding: 30px;
}
.pt30 {
  padding-top: 30px;
}
.pr30 {
  padding-right: 30px;
}
.pb30 {
  padding-bottom: 30px;
}
.pl30 {
  padding-left: 30px;
}
.pv30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.ph30 {
  padding-left: 30px;
  padding-right: 30px;
}
.p25 {
  padding: 25px;
}
.pt25 {
  padding-top: 25px;
}
.pr25 {
  padding-right: 25px;
}
.pb25 {
  padding-bottom: 25px;
}
.pl25 {
  padding-left: 25px;
}
.pv25 {
  padding-top: 25px;
  padding-bottom: 25px;
}
.ph25 {
  padding-left: 25px;
  padding-right: 25px;
}
.p20 {
  padding: 20px;
}
.pt20 {
  padding-top: 20px;
}
.pr20 {
  padding-right: 20px;
}
.pb20 {
  padding-bottom: 20px;
}
.pl20 {
  padding-left: 20px;
}
.pv20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.ph20 {
  padding-left: 20px;
  padding-right: 20px;
}
.p19 {
  padding: 19px;
}
.pt19 {
  padding-top: 19px;
}
.pr19 {
  padding-right: 19px;
}
.pb19 {
  padding-bottom: 19px;
}
.pl19 {
  padding-left: 19px;
}
.pv19 {
  padding-top: 19px;
  padding-bottom: 19px;
}
.ph19 {
  padding-left: 19px;
  padding-right: 19px;
}
.p18 {
  padding: 18px;
}
.pt18 {
  padding-top: 18px;
}
.pr18 {
  padding-right: 18px;
}
.pb18 {
  padding-bottom: 18px;
}
.pl18 {
  padding-left: 18px;
}
.pv18 {
  padding-top: 18px;
  padding-bottom: 18px;
}
.ph18 {
  padding-left: 18px;
  padding-right: 18px;
}
.p17 {
  padding: 17px;
}
.pt17 {
  padding-top: 17px;
}
.pr17 {
  padding-right: 17px;
}
.pb17 {
  padding-bottom: 17px;
}
.pl17 {
  padding-left: 17px;
}
.pv17 {
  padding-top: 17px;
  padding-bottom: 17px;
}
.ph17 {
  padding-left: 17px;
  padding-right: 17px;
}
.p16 {
  padding: 16px;
}
.pt16 {
  padding-top: 16px;
}
.pr16 {
  padding-right: 16px;
}
.pb16 {
  padding-bottom: 16px;
}
.pl16 {
  padding-left: 16px;
}
.pv16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.ph16 {
  padding-left: 16px;
  padding-right: 16px;
}
.p15 {
  padding: 15px;
}
.pt15 {
  padding-top: 15px;
}
.pr15 {
  padding-right: 15px;
}
.pb15 {
  padding-bottom: 15px;
}
.pl15 {
  padding-left: 15px;
}
.pv15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.ph15 {
  padding-left: 15px;
  padding-right: 15px;
}
.p14 {
  padding: 14px;
}
.pt14 {
  padding-top: 14px;
}
.pr14 {
  padding-right: 14px;
}
.pb14 {
  padding-bottom: 14px;
}
.pl14 {
  padding-left: 14px;
}
.pv14 {
  padding-top: 14px;
  padding-bottom: 14px;
}
.ph14 {
  padding-left: 14px;
  padding-right: 14px;
}
.p13 {
  padding: 13px;
}
.pt13 {
  padding-top: 13px;
}
.pr13 {
  padding-right: 13px;
}
.pb13 {
  padding-bottom: 13px;
}
.pl13 {
  padding-left: 13px;
}
.pv13 {
  padding-top: 13px;
  padding-bottom: 13px;
}
.ph13 {
  padding-left: 13px;
  padding-right: 13px;
}
.p12 {
  padding: 12px;
}
.pt12 {
  padding-top: 12px;
}
.pr12 {
  padding-right: 12px;
}
.pb12 {
  padding-bottom: 12px;
}
.pl12 {
  padding-left: 12px;
}
.pv12 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ph12 {
  padding-left: 12px;
  padding-right: 12px;
}
.p11 {
  padding: 11px;
}
.pt11 {
  padding-top: 11px;
}
.pr11 {
  padding-right: 11px;
}
.pb11 {
  padding-bottom: 11px;
}
.pl11 {
  padding-left: 11px;
}
.pv11 {
  padding-top: 11px;
  padding-bottom: 11px;
}
.ph11 {
  padding-left: 11px;
  padding-right: 11px;
}
.p10 {
  padding: 10px;
}
.pt10 {
  padding-top: 10px;
}
.pr10 {
  padding-right: 10px;
}
.pb10 {
  padding-bottom: 10px;
}
.pl10 {
  padding-left: 10px;
}
.pv10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.ph10 {
  padding-left: 10px;
  padding-right: 10px;
}
.p9 {
  padding: 9px;
}
.pt9 {
  padding-top: 9px;
}
.pr9 {
  padding-right: 9px;
}
.pb9 {
  padding-bottom: 9px;
}
.pl9 {
  padding-left: 9px;
}
.pv9 {
  padding-top: 9px;
  padding-bottom: 9px;
}
.ph9 {
  padding-left: 9px;
  padding-right: 9px;
}
.p8 {
  padding: 8px;
}
.pt8 {
  padding-top: 8px;
}
.pr8 {
  padding-right: 8px;
}
.pb8 {
  padding-bottom: 8px;
}
.pl8 {
  padding-left: 8px;
}
.pv8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.ph8 {
  padding-left: 8px;
  padding-right: 8px;
}
.p7 {
  padding: 7px;
}
.pt7 {
  padding-top: 7px;
}
.pr7 {
  padding-right: 7px;
}
.pb7 {
  padding-bottom: 7px;
}
.pl7 {
  padding-left: 7px;
}
.pv7 {
  padding-top: 7px;
  padding-bottom: 7px;
}
.ph7 {
  padding-left: 7px;
  padding-right: 7px;
}
.p6 {
  padding: 6px;
}
.pt6 {
  padding-top: 6px;
}
.pr6 {
  padding-right: 6px;
}
.pb6 {
  padding-bottom: 6px;
}
.pl6 {
  padding-left: 6px;
}
.pv6 {
  padding-top: 6px;
  padding-bottom: 6px;
}
.ph6 {
  padding-left: 6px;
  padding-right: 6px;
}
.p5 {
  padding: 5px;
}
.pt5 {
  padding-top: 5px;
}
.pr5 {
  padding-right: 5px;
}
.pb5 {
  padding-bottom: 5px;
}
.pl5 {
  padding-left: 5px;
}
.pv5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.ph5 {
  padding-left: 5px;
  padding-right: 5px;
}
.p4 {
  padding: 4px;
}
.pt4 {
  padding-top: 4px;
}
.pr4 {
  padding-right: 4px;
}
.pb4 {
  padding-bottom: 4px;
}
.pl4 {
  padding-left: 4px;
}
.pv4 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.ph4 {
  padding-left: 4px;
  padding-right: 4px;
}
.p3 {
  padding: 3px;
}
.pt3 {
  padding-top: 3px;
}
.pr3 {
  padding-right: 3px;
}
.pb3 {
  padding-bottom: 3px;
}
.pl3 {
  padding-left: 3px;
}
.pv3 {
  padding-top: 3px;
  padding-bottom: 3px;
}
.ph3 {
  padding-left: 3px;
  padding-right: 3px;
}
.p2 {
  padding: 2px;
}
.pt2 {
  padding-top: 2px;
}
.pr2 {
  padding-right: 2px;
}
.pb2 {
  padding-bottom: 2px;
}
.pl2 {
  padding-left: 2px;
}
.pv2 {
  padding-top: 2px;
  padding-bottom: 2px;
}
.ph2 {
  padding-left: 2px;
  padding-right: 2px;
}
.p1 {
  padding: 1px;
}
.pt1 {
  padding-top: 1px;
}
.pr1 {
  padding-right: 1px;
}
.pb1 {
  padding-bottom: 1px;
}
.pl1 {
  padding-left: 1px;
}
.pv1 {
  padding-top: 1px;
  padding-bottom: 1px;
}
.ph1 {
  padding-left: 1px;
  padding-right: 1px;
}
.p0 {
  padding: 0px;
}
.pt0 {
  padding-top: 0px;
}
.pr0 {
  padding-right: 0px;
}
.pb0 {
  padding-bottom: 0px;
}
.pl0 {
  padding-left: 0px;
}
.pv0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.ph0 {
  padding-left: 0px;
  padding-right: 0px;
}
.w400 {
  width: 400px;
}
.w400m {
  max-width: 400px;
}
.w400n {
  min-width: 400px;
}
.w400i {
  width: 400px !important;
}
.w395 {
  width: 395px;
}
.w395m {
  max-width: 395px;
}
.w395n {
  min-width: 395px;
}
.w395i {
  width: 395px !important;
}
.w390 {
  width: 390px;
}
.w390m {
  max-width: 390px;
}
.w390n {
  min-width: 390px;
}
.w390i {
  width: 390px !important;
}
.w385 {
  width: 385px;
}
.w385m {
  max-width: 385px;
}
.w385n {
  min-width: 385px;
}
.w385i {
  width: 385px !important;
}
.w380 {
  width: 380px;
}
.w380m {
  max-width: 380px;
}
.w380n {
  min-width: 380px;
}
.w380i {
  width: 380px !important;
}
.w375 {
  width: 375px;
}
.w375m {
  max-width: 375px;
}
.w375n {
  min-width: 375px;
}
.w375i {
  width: 375px !important;
}
.w370 {
  width: 370px;
}
.w370m {
  max-width: 370px;
}
.w370n {
  min-width: 370px;
}
.w370i {
  width: 370px !important;
}
.w365 {
  width: 365px;
}
.w365m {
  max-width: 365px;
}
.w365n {
  min-width: 365px;
}
.w365i {
  width: 365px !important;
}
.w360 {
  width: 360px;
}
.w360m {
  max-width: 360px;
}
.w360n {
  min-width: 360px;
}
.w360i {
  width: 360px !important;
}
.w355 {
  width: 355px;
}
.w355m {
  max-width: 355px;
}
.w355n {
  min-width: 355px;
}
.w355i {
  width: 355px !important;
}
.w350 {
  width: 350px;
}
.w350m {
  max-width: 350px;
}
.w350n {
  min-width: 350px;
}
.w350i {
  width: 350px !important;
}
.w345 {
  width: 345px;
}
.w345m {
  max-width: 345px;
}
.w345n {
  min-width: 345px;
}
.w345i {
  width: 345px !important;
}
.w340 {
  width: 340px;
}
.w340m {
  max-width: 340px;
}
.w340n {
  min-width: 340px;
}
.w340i {
  width: 340px !important;
}
.w335 {
  width: 335px;
}
.w335m {
  max-width: 335px;
}
.w335n {
  min-width: 335px;
}
.w335i {
  width: 335px !important;
}
.w330 {
  width: 330px;
}
.w330m {
  max-width: 330px;
}
.w330n {
  min-width: 330px;
}
.w330i {
  width: 330px !important;
}
.w325 {
  width: 325px;
}
.w325m {
  max-width: 325px;
}
.w325n {
  min-width: 325px;
}
.w325i {
  width: 325px !important;
}
.w320 {
  width: 320px;
}
.w320m {
  max-width: 320px;
}
.w320n {
  min-width: 320px;
}
.w320i {
  width: 320px !important;
}
.w315 {
  width: 315px;
}
.w315m {
  max-width: 315px;
}
.w315n {
  min-width: 315px;
}
.w315i {
  width: 315px !important;
}
.w310 {
  width: 310px;
}
.w310m {
  max-width: 310px;
}
.w310n {
  min-width: 310px;
}
.w310i {
  width: 310px !important;
}
.w305 {
  width: 305px;
}
.w305m {
  max-width: 305px;
}
.w305n {
  min-width: 305px;
}
.w305i {
  width: 305px !important;
}
.w300 {
  width: 300px;
}
.w300m {
  max-width: 300px;
}
.w300n {
  min-width: 300px;
}
.w300i {
  width: 300px !important;
}
.w295 {
  width: 295px;
}
.w295m {
  max-width: 295px;
}
.w295n {
  min-width: 295px;
}
.w295i {
  width: 295px !important;
}
.w290 {
  width: 290px;
}
.w290m {
  max-width: 290px;
}
.w290n {
  min-width: 290px;
}
.w290i {
  width: 290px !important;
}
.w285 {
  width: 285px;
}
.w285m {
  max-width: 285px;
}
.w285n {
  min-width: 285px;
}
.w285i {
  width: 285px !important;
}
.w280 {
  width: 280px;
}
.w280m {
  max-width: 280px;
}
.w280n {
  min-width: 280px;
}
.w280i {
  width: 280px !important;
}
.w275 {
  width: 275px;
}
.w275m {
  max-width: 275px;
}
.w275n {
  min-width: 275px;
}
.w275i {
  width: 275px !important;
}
.w270 {
  width: 270px;
}
.w270m {
  max-width: 270px;
}
.w270n {
  min-width: 270px;
}
.w270i {
  width: 270px !important;
}
.w265 {
  width: 265px;
}
.w265m {
  max-width: 265px;
}
.w265n {
  min-width: 265px;
}
.w265i {
  width: 265px !important;
}
.w260 {
  width: 260px;
}
.w260m {
  max-width: 260px;
}
.w260n {
  min-width: 260px;
}
.w260i {
  width: 260px !important;
}
.w255 {
  width: 255px;
}
.w255m {
  max-width: 255px;
}
.w255n {
  min-width: 255px;
}
.w255i {
  width: 255px !important;
}
.w250 {
  width: 250px;
}
.w250m {
  max-width: 250px;
}
.w250n {
  min-width: 250px;
}
.w250i {
  width: 250px !important;
}
.w245 {
  width: 245px;
}
.w245m {
  max-width: 245px;
}
.w245n {
  min-width: 245px;
}
.w245i {
  width: 245px !important;
}
.w240 {
  width: 240px;
}
.w240m {
  max-width: 240px;
}
.w240n {
  min-width: 240px;
}
.w240i {
  width: 240px !important;
}
.w235 {
  width: 235px;
}
.w235m {
  max-width: 235px;
}
.w235n {
  min-width: 235px;
}
.w235i {
  width: 235px !important;
}
.w230 {
  width: 230px;
}
.w230m {
  max-width: 230px;
}
.w230n {
  min-width: 230px;
}
.w230i {
  width: 230px !important;
}
.w225 {
  width: 225px;
}
.w225m {
  max-width: 225px;
}
.w225n {
  min-width: 225px;
}
.w225i {
  width: 225px !important;
}
.w220 {
  width: 220px;
}
.w220m {
  max-width: 220px;
}
.w220n {
  min-width: 220px;
}
.w220i {
  width: 220px !important;
}
.w215 {
  width: 215px;
}
.w215m {
  max-width: 215px;
}
.w215n {
  min-width: 215px;
}
.w215i {
  width: 215px !important;
}
.w210 {
  width: 210px;
}
.w210m {
  max-width: 210px;
}
.w210n {
  min-width: 210px;
}
.w210i {
  width: 210px !important;
}
.w205 {
  width: 205px;
}
.w205m {
  max-width: 205px;
}
.w205n {
  min-width: 205px;
}
.w205i {
  width: 205px !important;
}
.w200 {
  width: 200px;
}
.w200m {
  max-width: 200px;
}
.w200n {
  min-width: 200px;
}
.w200i {
  width: 200px !important;
}
.w195 {
  width: 195px;
}
.w195m {
  max-width: 195px;
}
.w195n {
  min-width: 195px;
}
.w195i {
  width: 195px !important;
}
.w190 {
  width: 190px;
}
.w190m {
  max-width: 190px;
}
.w190n {
  min-width: 190px;
}
.w190i {
  width: 190px !important;
}
.w185 {
  width: 185px;
}
.w185m {
  max-width: 185px;
}
.w185n {
  min-width: 185px;
}
.w185i {
  width: 185px !important;
}
.w180 {
  width: 180px;
}
.w180m {
  max-width: 180px;
}
.w180n {
  min-width: 180px;
}
.w180i {
  width: 180px !important;
}
.w175 {
  width: 175px;
}
.w175m {
  max-width: 175px;
}
.w175n {
  min-width: 175px;
}
.w175i {
  width: 175px !important;
}
.w170 {
  width: 170px;
}
.w170m {
  max-width: 170px;
}
.w170n {
  min-width: 170px;
}
.w170i {
  width: 170px !important;
}
.w165 {
  width: 165px;
}
.w165m {
  max-width: 165px;
}
.w165n {
  min-width: 165px;
}
.w165i {
  width: 165px !important;
}
.w160 {
  width: 160px;
}
.w160m {
  max-width: 160px;
}
.w160n {
  min-width: 160px;
}
.w160i {
  width: 160px !important;
}
.w155 {
  width: 155px;
}
.w155m {
  max-width: 155px;
}
.w155n {
  min-width: 155px;
}
.w155i {
  width: 155px !important;
}
.w150 {
  width: 150px;
}
.w150m {
  max-width: 150px;
}
.w150n {
  min-width: 150px;
}
.w150i {
  width: 150px !important;
}
.w145 {
  width: 145px;
}
.w145m {
  max-width: 145px;
}
.w145n {
  min-width: 145px;
}
.w145i {
  width: 145px !important;
}
.w140 {
  width: 140px;
}
.w140m {
  max-width: 140px;
}
.w140n {
  min-width: 140px;
}
.w140i {
  width: 140px !important;
}
.w135 {
  width: 135px;
}
.w135m {
  max-width: 135px;
}
.w135n {
  min-width: 135px;
}
.w135i {
  width: 135px !important;
}
.w130 {
  width: 130px;
}
.w130m {
  max-width: 130px;
}
.w130n {
  min-width: 130px;
}
.w130i {
  width: 130px !important;
}
.w125 {
  width: 125px;
}
.w125m {
  max-width: 125px;
}
.w125n {
  min-width: 125px;
}
.w125i {
  width: 125px !important;
}
.w120 {
  width: 120px;
}
.w120m {
  max-width: 120px;
}
.w120n {
  min-width: 120px;
}
.w120i {
  width: 120px !important;
}
.w115 {
  width: 115px;
}
.w115m {
  max-width: 115px;
}
.w115n {
  min-width: 115px;
}
.w115i {
  width: 115px !important;
}
.w110 {
  width: 110px;
}
.w110m {
  max-width: 110px;
}
.w110n {
  min-width: 110px;
}
.w110i {
  width: 110px !important;
}
.w105 {
  width: 105px;
}
.w105m {
  max-width: 105px;
}
.w105n {
  min-width: 105px;
}
.w105i {
  width: 105px !important;
}
.w100 {
  width: 100px;
}
.w100m {
  max-width: 100px;
}
.w100n {
  min-width: 100px;
}
.w100i {
  width: 100px !important;
}
.w95 {
  width: 95px;
}
.w95m {
  max-width: 95px;
}
.w95n {
  min-width: 95px;
}
.w95i {
  width: 95px !important;
}
.w90 {
  width: 90px;
}
.w90m {
  max-width: 90px;
}
.w90n {
  min-width: 90px;
}
.w90i {
  width: 90px !important;
}
.w85 {
  width: 85px;
}
.w85m {
  max-width: 85px;
}
.w85n {
  min-width: 85px;
}
.w85i {
  width: 85px !important;
}
.w80 {
  width: 80px;
}
.w80m {
  max-width: 80px;
}
.w80n {
  min-width: 80px;
}
.w80i {
  width: 80px !important;
}
.w75 {
  width: 75px;
}
.w75m {
  max-width: 75px;
}
.w75n {
  min-width: 75px;
}
.w75i {
  width: 75px !important;
}
.w70 {
  width: 70px;
}
.w70m {
  max-width: 70px;
}
.w70n {
  min-width: 70px;
}
.w70i {
  width: 70px !important;
}
.w65 {
  width: 65px;
}
.w65m {
  max-width: 65px;
}
.w65n {
  min-width: 65px;
}
.w65i {
  width: 65px !important;
}
.w60 {
  width: 60px;
}
.w60m {
  max-width: 60px;
}
.w60n {
  min-width: 60px;
}
.w60i {
  width: 60px !important;
}
.w55 {
  width: 55px;
}
.w55m {
  max-width: 55px;
}
.w55n {
  min-width: 55px;
}
.w55i {
  width: 55px !important;
}
.w50 {
  width: 50px;
}
.w50m {
  max-width: 50px;
}
.w50n {
  min-width: 50px;
}
.w50i {
  width: 50px !important;
}
.w45 {
  width: 45px;
}
.w45m {
  max-width: 45px;
}
.w45n {
  min-width: 45px;
}
.w45i {
  width: 45px !important;
}
.w40 {
  width: 40px;
}
.w40m {
  max-width: 40px;
}
.w40n {
  min-width: 40px;
}
.w40i {
  width: 40px !important;
}
.w35 {
  width: 35px;
}
.w35m {
  max-width: 35px;
}
.w35n {
  min-width: 35px;
}
.w35i {
  width: 35px !important;
}
.w30 {
  width: 30px;
}
.w30m {
  max-width: 30px;
}
.w30n {
  min-width: 30px;
}
.w30i {
  width: 30px !important;
}
.w25 {
  width: 25px;
}
.w25m {
  max-width: 25px;
}
.w25n {
  min-width: 25px;
}
.w25i {
  width: 25px !important;
}
.w20 {
  width: 20px;
}
.w20m {
  max-width: 20px;
}
.w20n {
  min-width: 20px;
}
.w20i {
  width: 20px !important;
}
.w19 {
  width: 19px;
}
.w19m {
  max-width: 19px;
}
.w19n {
  min-width: 19px;
}
.w19i {
  width: 19px !important;
}
.w18 {
  width: 18px;
}
.w18m {
  max-width: 18px;
}
.w18n {
  min-width: 18px;
}
.w18i {
  width: 18px !important;
}
.w17 {
  width: 17px;
}
.w17m {
  max-width: 17px;
}
.w17n {
  min-width: 17px;
}
.w17i {
  width: 17px !important;
}
.w16 {
  width: 16px;
}
.w16m {
  max-width: 16px;
}
.w16n {
  min-width: 16px;
}
.w16i {
  width: 16px !important;
}
.w15 {
  width: 15px;
}
.w15m {
  max-width: 15px;
}
.w15n {
  min-width: 15px;
}
.w15i {
  width: 15px !important;
}
.w14 {
  width: 14px;
}
.w14m {
  max-width: 14px;
}
.w14n {
  min-width: 14px;
}
.w14i {
  width: 14px !important;
}
.w13 {
  width: 13px;
}
.w13m {
  max-width: 13px;
}
.w13n {
  min-width: 13px;
}
.w13i {
  width: 13px !important;
}
.w12 {
  width: 12px;
}
.w12m {
  max-width: 12px;
}
.w12n {
  min-width: 12px;
}
.w12i {
  width: 12px !important;
}
.w11 {
  width: 11px;
}
.w11m {
  max-width: 11px;
}
.w11n {
  min-width: 11px;
}
.w11i {
  width: 11px !important;
}
.w10 {
  width: 10px;
}
.w10m {
  max-width: 10px;
}
.w10n {
  min-width: 10px;
}
.w10i {
  width: 10px !important;
}
.w9 {
  width: 9px;
}
.w9m {
  max-width: 9px;
}
.w9n {
  min-width: 9px;
}
.w9i {
  width: 9px !important;
}
.w8 {
  width: 8px;
}
.w8m {
  max-width: 8px;
}
.w8n {
  min-width: 8px;
}
.w8i {
  width: 8px !important;
}
.w7 {
  width: 7px;
}
.w7m {
  max-width: 7px;
}
.w7n {
  min-width: 7px;
}
.w7i {
  width: 7px !important;
}
.w6 {
  width: 6px;
}
.w6m {
  max-width: 6px;
}
.w6n {
  min-width: 6px;
}
.w6i {
  width: 6px !important;
}
.w5 {
  width: 5px;
}
.w5m {
  max-width: 5px;
}
.w5n {
  min-width: 5px;
}
.w5i {
  width: 5px !important;
}
.w4 {
  width: 4px;
}
.w4m {
  max-width: 4px;
}
.w4n {
  min-width: 4px;
}
.w4i {
  width: 4px !important;
}
.w3 {
  width: 3px;
}
.w3m {
  max-width: 3px;
}
.w3n {
  min-width: 3px;
}
.w3i {
  width: 3px !important;
}
.w2 {
  width: 2px;
}
.w2m {
  max-width: 2px;
}
.w2n {
  min-width: 2px;
}
.w2i {
  width: 2px !important;
}
.w1 {
  width: 1px;
}
.w1m {
  max-width: 1px;
}
.w1n {
  min-width: 1px;
}
.w1i {
  width: 1px !important;
}
.w0 {
  width: 0px;
}
.w0m {
  max-width: 0px;
}
.w0n {
  min-width: 0px;
}
.w0i {
  width: 0px !important;
}
.h400 {
  height: 400px;
}
.lh400 {
  line-height: 400px;
}
.h400m {
  max-height: 400px;
}
.h400n {
  min-height: 400px;
}
.h400i {
  height: 400px !important;
}
.h395 {
  height: 395px;
}
.lh395 {
  line-height: 395px;
}
.h395m {
  max-height: 395px;
}
.h395n {
  min-height: 395px;
}
.h395i {
  height: 395px !important;
}
.h390 {
  height: 390px;
}
.lh390 {
  line-height: 390px;
}
.h390m {
  max-height: 390px;
}
.h390n {
  min-height: 390px;
}
.h390i {
  height: 390px !important;
}
.h385 {
  height: 385px;
}
.lh385 {
  line-height: 385px;
}
.h385m {
  max-height: 385px;
}
.h385n {
  min-height: 385px;
}
.h385i {
  height: 385px !important;
}
.h380 {
  height: 380px;
}
.lh380 {
  line-height: 380px;
}
.h380m {
  max-height: 380px;
}
.h380n {
  min-height: 380px;
}
.h380i {
  height: 380px !important;
}
.h375 {
  height: 375px;
}
.lh375 {
  line-height: 375px;
}
.h375m {
  max-height: 375px;
}
.h375n {
  min-height: 375px;
}
.h375i {
  height: 375px !important;
}
.h370 {
  height: 370px;
}
.lh370 {
  line-height: 370px;
}
.h370m {
  max-height: 370px;
}
.h370n {
  min-height: 370px;
}
.h370i {
  height: 370px !important;
}
.h365 {
  height: 365px;
}
.lh365 {
  line-height: 365px;
}
.h365m {
  max-height: 365px;
}
.h365n {
  min-height: 365px;
}
.h365i {
  height: 365px !important;
}
.h360 {
  height: 360px;
}
.lh360 {
  line-height: 360px;
}
.h360m {
  max-height: 360px;
}
.h360n {
  min-height: 360px;
}
.h360i {
  height: 360px !important;
}
.h355 {
  height: 355px;
}
.lh355 {
  line-height: 355px;
}
.h355m {
  max-height: 355px;
}
.h355n {
  min-height: 355px;
}
.h355i {
  height: 355px !important;
}
.h350 {
  height: 350px;
}
.lh350 {
  line-height: 350px;
}
.h350m {
  max-height: 350px;
}
.h350n {
  min-height: 350px;
}
.h350i {
  height: 350px !important;
}
.h345 {
  height: 345px;
}
.lh345 {
  line-height: 345px;
}
.h345m {
  max-height: 345px;
}
.h345n {
  min-height: 345px;
}
.h345i {
  height: 345px !important;
}
.h340 {
  height: 340px;
}
.lh340 {
  line-height: 340px;
}
.h340m {
  max-height: 340px;
}
.h340n {
  min-height: 340px;
}
.h340i {
  height: 340px !important;
}
.h335 {
  height: 335px;
}
.lh335 {
  line-height: 335px;
}
.h335m {
  max-height: 335px;
}
.h335n {
  min-height: 335px;
}
.h335i {
  height: 335px !important;
}
.h330 {
  height: 330px;
}
.lh330 {
  line-height: 330px;
}
.h330m {
  max-height: 330px;
}
.h330n {
  min-height: 330px;
}
.h330i {
  height: 330px !important;
}
.h325 {
  height: 325px;
}
.lh325 {
  line-height: 325px;
}
.h325m {
  max-height: 325px;
}
.h325n {
  min-height: 325px;
}
.h325i {
  height: 325px !important;
}
.h320 {
  height: 320px;
}
.lh320 {
  line-height: 320px;
}
.h320m {
  max-height: 320px;
}
.h320n {
  min-height: 320px;
}
.h320i {
  height: 320px !important;
}
.h315 {
  height: 315px;
}
.lh315 {
  line-height: 315px;
}
.h315m {
  max-height: 315px;
}
.h315n {
  min-height: 315px;
}
.h315i {
  height: 315px !important;
}
.h310 {
  height: 310px;
}
.lh310 {
  line-height: 310px;
}
.h310m {
  max-height: 310px;
}
.h310n {
  min-height: 310px;
}
.h310i {
  height: 310px !important;
}
.h305 {
  height: 305px;
}
.lh305 {
  line-height: 305px;
}
.h305m {
  max-height: 305px;
}
.h305n {
  min-height: 305px;
}
.h305i {
  height: 305px !important;
}
.h300 {
  height: 300px;
}
.lh300 {
  line-height: 300px;
}
.h300m {
  max-height: 300px;
}
.h300n {
  min-height: 300px;
}
.h300i {
  height: 300px !important;
}
.h295 {
  height: 295px;
}
.lh295 {
  line-height: 295px;
}
.h295m {
  max-height: 295px;
}
.h295n {
  min-height: 295px;
}
.h295i {
  height: 295px !important;
}
.h290 {
  height: 290px;
}
.lh290 {
  line-height: 290px;
}
.h290m {
  max-height: 290px;
}
.h290n {
  min-height: 290px;
}
.h290i {
  height: 290px !important;
}
.h285 {
  height: 285px;
}
.lh285 {
  line-height: 285px;
}
.h285m {
  max-height: 285px;
}
.h285n {
  min-height: 285px;
}
.h285i {
  height: 285px !important;
}
.h280 {
  height: 280px;
}
.lh280 {
  line-height: 280px;
}
.h280m {
  max-height: 280px;
}
.h280n {
  min-height: 280px;
}
.h280i {
  height: 280px !important;
}
.h275 {
  height: 275px;
}
.lh275 {
  line-height: 275px;
}
.h275m {
  max-height: 275px;
}
.h275n {
  min-height: 275px;
}
.h275i {
  height: 275px !important;
}
.h270 {
  height: 270px;
}
.lh270 {
  line-height: 270px;
}
.h270m {
  max-height: 270px;
}
.h270n {
  min-height: 270px;
}
.h270i {
  height: 270px !important;
}
.h265 {
  height: 265px;
}
.lh265 {
  line-height: 265px;
}
.h265m {
  max-height: 265px;
}
.h265n {
  min-height: 265px;
}
.h265i {
  height: 265px !important;
}
.h260 {
  height: 260px;
}
.lh260 {
  line-height: 260px;
}
.h260m {
  max-height: 260px;
}
.h260n {
  min-height: 260px;
}
.h260i {
  height: 260px !important;
}
.h255 {
  height: 255px;
}
.lh255 {
  line-height: 255px;
}
.h255m {
  max-height: 255px;
}
.h255n {
  min-height: 255px;
}
.h255i {
  height: 255px !important;
}
.h250 {
  height: 250px;
}
.lh250 {
  line-height: 250px;
}
.h250m {
  max-height: 250px;
}
.h250n {
  min-height: 250px;
}
.h250i {
  height: 250px !important;
}
.h245 {
  height: 245px;
}
.lh245 {
  line-height: 245px;
}
.h245m {
  max-height: 245px;
}
.h245n {
  min-height: 245px;
}
.h245i {
  height: 245px !important;
}
.h240 {
  height: 240px;
}
.lh240 {
  line-height: 240px;
}
.h240m {
  max-height: 240px;
}
.h240n {
  min-height: 240px;
}
.h240i {
  height: 240px !important;
}
.h235 {
  height: 235px;
}
.lh235 {
  line-height: 235px;
}
.h235m {
  max-height: 235px;
}
.h235n {
  min-height: 235px;
}
.h235i {
  height: 235px !important;
}
.h230 {
  height: 230px;
}
.lh230 {
  line-height: 230px;
}
.h230m {
  max-height: 230px;
}
.h230n {
  min-height: 230px;
}
.h230i {
  height: 230px !important;
}
.h225 {
  height: 225px;
}
.lh225 {
  line-height: 225px;
}
.h225m {
  max-height: 225px;
}
.h225n {
  min-height: 225px;
}
.h225i {
  height: 225px !important;
}
.h220 {
  height: 220px;
}
.lh220 {
  line-height: 220px;
}
.h220m {
  max-height: 220px;
}
.h220n {
  min-height: 220px;
}
.h220i {
  height: 220px !important;
}
.h215 {
  height: 215px;
}
.lh215 {
  line-height: 215px;
}
.h215m {
  max-height: 215px;
}
.h215n {
  min-height: 215px;
}
.h215i {
  height: 215px !important;
}
.h210 {
  height: 210px;
}
.lh210 {
  line-height: 210px;
}
.h210m {
  max-height: 210px;
}
.h210n {
  min-height: 210px;
}
.h210i {
  height: 210px !important;
}
.h205 {
  height: 205px;
}
.lh205 {
  line-height: 205px;
}
.h205m {
  max-height: 205px;
}
.h205n {
  min-height: 205px;
}
.h205i {
  height: 205px !important;
}
.h200 {
  height: 200px;
}
.lh200 {
  line-height: 200px;
}
.h200m {
  max-height: 200px;
}
.h200n {
  min-height: 200px;
}
.h200i {
  height: 200px !important;
}
.h195 {
  height: 195px;
}
.lh195 {
  line-height: 195px;
}
.h195m {
  max-height: 195px;
}
.h195n {
  min-height: 195px;
}
.h195i {
  height: 195px !important;
}
.h190 {
  height: 190px;
}
.lh190 {
  line-height: 190px;
}
.h190m {
  max-height: 190px;
}
.h190n {
  min-height: 190px;
}
.h190i {
  height: 190px !important;
}
.h185 {
  height: 185px;
}
.lh185 {
  line-height: 185px;
}
.h185m {
  max-height: 185px;
}
.h185n {
  min-height: 185px;
}
.h185i {
  height: 185px !important;
}
.h180 {
  height: 180px;
}
.lh180 {
  line-height: 180px;
}
.h180m {
  max-height: 180px;
}
.h180n {
  min-height: 180px;
}
.h180i {
  height: 180px !important;
}
.h175 {
  height: 175px;
}
.lh175 {
  line-height: 175px;
}
.h175m {
  max-height: 175px;
}
.h175n {
  min-height: 175px;
}
.h175i {
  height: 175px !important;
}
.h170 {
  height: 170px;
}
.lh170 {
  line-height: 170px;
}
.h170m {
  max-height: 170px;
}
.h170n {
  min-height: 170px;
}
.h170i {
  height: 170px !important;
}
.h165 {
  height: 165px;
}
.lh165 {
  line-height: 165px;
}
.h165m {
  max-height: 165px;
}
.h165n {
  min-height: 165px;
}
.h165i {
  height: 165px !important;
}
.h160 {
  height: 160px;
}
.lh160 {
  line-height: 160px;
}
.h160m {
  max-height: 160px;
}
.h160n {
  min-height: 160px;
}
.h160i {
  height: 160px !important;
}
.h155 {
  height: 155px;
}
.lh155 {
  line-height: 155px;
}
.h155m {
  max-height: 155px;
}
.h155n {
  min-height: 155px;
}
.h155i {
  height: 155px !important;
}
.h150 {
  height: 150px;
}
.lh150 {
  line-height: 150px;
}
.h150m {
  max-height: 150px;
}
.h150n {
  min-height: 150px;
}
.h150i {
  height: 150px !important;
}
.h145 {
  height: 145px;
}
.lh145 {
  line-height: 145px;
}
.h145m {
  max-height: 145px;
}
.h145n {
  min-height: 145px;
}
.h145i {
  height: 145px !important;
}
.h140 {
  height: 140px;
}
.lh140 {
  line-height: 140px;
}
.h140m {
  max-height: 140px;
}
.h140n {
  min-height: 140px;
}
.h140i {
  height: 140px !important;
}
.h135 {
  height: 135px;
}
.lh135 {
  line-height: 135px;
}
.h135m {
  max-height: 135px;
}
.h135n {
  min-height: 135px;
}
.h135i {
  height: 135px !important;
}
.h130 {
  height: 130px;
}
.lh130 {
  line-height: 130px;
}
.h130m {
  max-height: 130px;
}
.h130n {
  min-height: 130px;
}
.h130i {
  height: 130px !important;
}
.h125 {
  height: 125px;
}
.lh125 {
  line-height: 125px;
}
.h125m {
  max-height: 125px;
}
.h125n {
  min-height: 125px;
}
.h125i {
  height: 125px !important;
}
.h120 {
  height: 120px;
}
.lh120 {
  line-height: 120px;
}
.h120m {
  max-height: 120px;
}
.h120n {
  min-height: 120px;
}
.h120i {
  height: 120px !important;
}
.h115 {
  height: 115px;
}
.lh115 {
  line-height: 115px;
}
.h115m {
  max-height: 115px;
}
.h115n {
  min-height: 115px;
}
.h115i {
  height: 115px !important;
}
.h110 {
  height: 110px;
}
.lh110 {
  line-height: 110px;
}
.h110m {
  max-height: 110px;
}
.h110n {
  min-height: 110px;
}
.h110i {
  height: 110px !important;
}
.h105 {
  height: 105px;
}
.lh105 {
  line-height: 105px;
}
.h105m {
  max-height: 105px;
}
.h105n {
  min-height: 105px;
}
.h105i {
  height: 105px !important;
}
.h100 {
  height: 100px;
}
.lh100 {
  line-height: 100px;
}
.h100m {
  max-height: 100px;
}
.h100n {
  min-height: 100px;
}
.h100i {
  height: 100px !important;
}
.h95 {
  height: 95px;
}
.lh95 {
  line-height: 95px;
}
.h95m {
  max-height: 95px;
}
.h95n {
  min-height: 95px;
}
.h95i {
  height: 95px !important;
}
.h90 {
  height: 90px;
}
.lh90 {
  line-height: 90px;
}
.h90m {
  max-height: 90px;
}
.h90n {
  min-height: 90px;
}
.h90i {
  height: 90px !important;
}
.h85 {
  height: 85px;
}
.lh85 {
  line-height: 85px;
}
.h85m {
  max-height: 85px;
}
.h85n {
  min-height: 85px;
}
.h85i {
  height: 85px !important;
}
.h80 {
  height: 80px;
}
.lh80 {
  line-height: 80px;
}
.h80m {
  max-height: 80px;
}
.h80n {
  min-height: 80px;
}
.h80i {
  height: 80px !important;
}
.h75 {
  height: 75px;
}
.lh75 {
  line-height: 75px;
}
.h75m {
  max-height: 75px;
}
.h75n {
  min-height: 75px;
}
.h75i {
  height: 75px !important;
}
.h70 {
  height: 70px;
}
.lh70 {
  line-height: 70px;
}
.h70m {
  max-height: 70px;
}
.h70n {
  min-height: 70px;
}
.h70i {
  height: 70px !important;
}
.h65 {
  height: 65px;
}
.lh65 {
  line-height: 65px;
}
.h65m {
  max-height: 65px;
}
.h65n {
  min-height: 65px;
}
.h65i {
  height: 65px !important;
}
.h60 {
  height: 60px;
}
.lh60 {
  line-height: 60px;
}
.h60m {
  max-height: 60px;
}
.h60n {
  min-height: 60px;
}
.h60i {
  height: 60px !important;
}
.h55 {
  height: 55px;
}
.lh55 {
  line-height: 55px;
}
.h55m {
  max-height: 55px;
}
.h55n {
  min-height: 55px;
}
.h55i {
  height: 55px !important;
}
.h50 {
  height: 50px;
}
.lh50 {
  line-height: 50px;
}
.h50m {
  max-height: 50px;
}
.h50n {
  min-height: 50px;
}
.h50i {
  height: 50px !important;
}
.h45 {
  height: 45px;
}
.lh45 {
  line-height: 45px;
}
.h45m {
  max-height: 45px;
}
.h45n {
  min-height: 45px;
}
.h45i {
  height: 45px !important;
}
.h40 {
  height: 40px;
}
.lh40 {
  line-height: 40px;
}
.h40m {
  max-height: 40px;
}
.h40n {
  min-height: 40px;
}
.h40i {
  height: 40px !important;
}
.h35 {
  height: 35px;
}
.lh35 {
  line-height: 35px;
}
.h35m {
  max-height: 35px;
}
.h35n {
  min-height: 35px;
}
.h35i {
  height: 35px !important;
}
.h30 {
  height: 30px;
}
.lh30 {
  line-height: 30px;
}
.h30m {
  max-height: 30px;
}
.h30n {
  min-height: 30px;
}
.h30i {
  height: 30px !important;
}
.h25 {
  height: 25px;
}
.lh25 {
  line-height: 25px;
}
.h25m {
  max-height: 25px;
}
.h25n {
  min-height: 25px;
}
.h25i {
  height: 25px !important;
}
.h20 {
  height: 20px;
}
.lh20 {
  line-height: 20px;
}
.h20m {
  max-height: 20px;
}
.h20n {
  min-height: 20px;
}
.h20i {
  height: 20px !important;
}
.h19 {
  height: 19px;
}
.lh19 {
  line-height: 19px;
}
.h19m {
  max-height: 19px;
}
.h19n {
  min-height: 19px;
}
.h19i {
  height: 19px !important;
}
.h18 {
  height: 18px;
}
.lh18 {
  line-height: 18px;
}
.h18m {
  max-height: 18px;
}
.h18n {
  min-height: 18px;
}
.h18i {
  height: 18px !important;
}
.h17 {
  height: 17px;
}
.lh17 {
  line-height: 17px;
}
.h17m {
  max-height: 17px;
}
.h17n {
  min-height: 17px;
}
.h17i {
  height: 17px !important;
}
.h16 {
  height: 16px;
}
.lh16 {
  line-height: 16px;
}
.h16m {
  max-height: 16px;
}
.h16n {
  min-height: 16px;
}
.h16i {
  height: 16px !important;
}
.h15 {
  height: 15px;
}
.lh15 {
  line-height: 15px;
}
.h15m {
  max-height: 15px;
}
.h15n {
  min-height: 15px;
}
.h15i {
  height: 15px !important;
}
.h14 {
  height: 14px;
}
.lh14 {
  line-height: 14px;
}
.h14m {
  max-height: 14px;
}
.h14n {
  min-height: 14px;
}
.h14i {
  height: 14px !important;
}
.h13 {
  height: 13px;
}
.lh13 {
  line-height: 13px;
}
.h13m {
  max-height: 13px;
}
.h13n {
  min-height: 13px;
}
.h13i {
  height: 13px !important;
}
.h12 {
  height: 12px;
}
.lh12 {
  line-height: 12px;
}
.h12m {
  max-height: 12px;
}
.h12n {
  min-height: 12px;
}
.h12i {
  height: 12px !important;
}
.h11 {
  height: 11px;
}
.lh11 {
  line-height: 11px;
}
.h11m {
  max-height: 11px;
}
.h11n {
  min-height: 11px;
}
.h11i {
  height: 11px !important;
}
.h10 {
  height: 10px;
}
.lh10 {
  line-height: 10px;
}
.h10m {
  max-height: 10px;
}
.h10n {
  min-height: 10px;
}
.h10i {
  height: 10px !important;
}
.h9 {
  height: 9px;
}
.lh9 {
  line-height: 9px;
}
.h9m {
  max-height: 9px;
}
.h9n {
  min-height: 9px;
}
.h9i {
  height: 9px !important;
}
.h8 {
  height: 8px;
}
.lh8 {
  line-height: 8px;
}
.h8m {
  max-height: 8px;
}
.h8n {
  min-height: 8px;
}
.h8i {
  height: 8px !important;
}
.h7 {
  height: 7px;
}
.lh7 {
  line-height: 7px;
}
.h7m {
  max-height: 7px;
}
.h7n {
  min-height: 7px;
}
.h7i {
  height: 7px !important;
}
.h6 {
  height: 6px;
}
.lh6 {
  line-height: 6px;
}
.h6m {
  max-height: 6px;
}
.h6n {
  min-height: 6px;
}
.h6i {
  height: 6px !important;
}
.h5 {
  height: 5px;
}
.lh5 {
  line-height: 5px;
}
.h5m {
  max-height: 5px;
}
.h5n {
  min-height: 5px;
}
.h5i {
  height: 5px !important;
}
.h4 {
  height: 4px;
}
.lh4 {
  line-height: 4px;
}
.h4m {
  max-height: 4px;
}
.h4n {
  min-height: 4px;
}
.h4i {
  height: 4px !important;
}
.h3 {
  height: 3px;
}
.lh3 {
  line-height: 3px;
}
.h3m {
  max-height: 3px;
}
.h3n {
  min-height: 3px;
}
.h3i {
  height: 3px !important;
}
.h2 {
  height: 2px;
}
.lh2 {
  line-height: 2px;
}
.h2m {
  max-height: 2px;
}
.h2n {
  min-height: 2px;
}
.h2i {
  height: 2px !important;
}
.h1 {
  height: 1px;
}
.lh1 {
  line-height: 1px;
}
.h1m {
  max-height: 1px;
}
.h1n {
  min-height: 1px;
}
.h1i {
  height: 1px !important;
}
.h0 {
  height: 0px;
}
.lh0 {
  line-height: 0px;
}
.h0m {
  max-height: 0px;
}
.h0n {
  min-height: 0px;
}
.h0i {
  height: 0px !important;
}
.compulsory:before {
  content: "*";
  color: red;
}
.limit-hints {
  font-size: 10px;
  color: #aaaaaa;
  float: right;
}
.hover-underline {
  cursor: pointer;
}
.hover-underline:hover {
  text-decoration: underline;
}
.list-text-restriction {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  position: relative;
  line-height: 17px;
  max-height: 51px;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
}
.one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.inline-block {
  display: inline-block;
}
.overflow-hidden {
  overflow: hidden;
}
a {
  outline: none;
}
a:focus,
a:hover,
a:active {
  outline: none;
}
.mobile-container {
  max-width: 400px;
  border: 1px solid #eee;
  padding: 10px;
  margin: 10px auto;
  display: block;
  background-color: white;
}
.wrap-word {
  word-wrap: break-word;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.display-none {
  display: none !important;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.link {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration-skip: objects;
}
.avatar-large {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  cursor: pointer;
}
.avatar-middle {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  cursor: pointer;
}
.avatar-small {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.relative {
  position: relative;
}
.hot-area {
  position: relative;
}
.hot-area:after {
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  content: '';
}
@media (min-width: 992px) {
  .visible-mobile {
    display: none !important;
  }
  .visible-pc {
    display: block !important;
  }
}
@media (max-width: 992px) {
  .visible-mobile {
    display: block !important;
  }
  .visible-pc {
    display: none !important;
  }
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  transition: all 0.3s ease;
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 40;
}
::-webkit-scrollbar-thumb:hover {
  transition: all 0.3s ease;
  background-color: rgba(0, 0, 0, 0.15);
}
::-webkit-scrollbar-corner {
  background-color: #eaeaeb;
}
html,
body {
  font-family: "微软雅黑", Microsoft YaHei, "Helvetica Neue", Helvetica, Arial, sans-serif, "open sans";
  font-size: 13px;
  color: #333333;
}

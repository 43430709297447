//页面的导航样式
.widget-tank-title {
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #E6E6E6;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .item {
    font-size: 18px;
    color: #778195;
    display: inline-block;
    box-sizing: content-box;
    line-height: 30px;

    &:hover, &.active {
      color: #333;
      border-bottom: 2px solid #333;
    }
  }

  .tool {
    flex: 1;
    padding-bottom: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

  }
}

@primary-color: #215891;
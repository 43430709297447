
.widget-create-panel {

  .install-block {
    padding: 20px 15px 10px 15px;
    border-bottom: 1px solid #eee;
  }

}

@primary-color: #215891;
.widget-filter-panel {
  .filter-block {
    display: inline-block;

    .filter-cell {
      display: inline-block;
      margin-right: 15px;
      margin-bottom: 10px;

      .filter-name {
        font-weight: bold;
        margin-right: 10px;
      }

      .filter-body {
        display: inline-block;
      }
    }

  }

  &.selection-button-loose {
    .selection-button-filter-box, .http-selection-combobox-filter-box, .http-selection-button-filter-box {
      display: block;

      .filter-cell {
        margin: 0;

        .ant-radio-button-wrapper {
          // border: none;
          margin-right: 10px;
          margin-bottom: 10px;
          border-radius: 4px;
        }

        .ant-radio-button-wrapper-checked {
          color: #fff;
          background-color: #1890ff;
          border-color: #1890ff;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
          box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
          margin-right: 10px

        }

        .ant-select {
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }


  .operation-area {
    .ant-btn {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  .user-filter-box {
    .filter-body {
      min-width: 200px;
    }
  }
}

@primary-color: #215891;
.about-modal {
  max-width: 500px;
}

.about-modal-box {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .item {
    margin-bottom: 5px;
  }

  .brand {
    white-space: pre;
  }
}



@primary-color: #215891;
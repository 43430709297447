//使用自定义的主题
@import "global/animation";
@import "global/border";
@import "global/button";
@import "global/color";
@import "global/font";
@import "global/layout";
@import "global/miscellaneous";
@import "global/responsive";

html, body {
  font-family: "微软雅黑", Microsoft YaHei, "Helvetica Neue", Helvetica, Arial, sans-serif, "open sans";
  font-size: 13px;
  color: @text-color;


}

@base-height: 48px;
@inner-cell-height: 36px;

.basic-span {
  display: inline-block;
  vertical-align: middle;
  line-height: @base-height;
  margin-right: 10px;
}

.widget-share-bar {

  border-top: 1px solid #eee;
  background-color: white;

  .btn-action {
    font-size: 15px;
  }

  .media {
    > .pull-left {
      padding-right: 1px;
    }
  }

  .share-icon {
    width: 24px;
  }

  .left-part {
    margin-left: 10px;
  }

  .middle-part {

    height: @base-height;
    overflow: hidden;

    .share-name-edit {
      .basic-span;

      width: 90%;

      input {
        width: 100%;
        height: 26px;
        display: inline-block;
        padding: 6px;
      }
    }

    .share-name {
      .basic-span;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .right-part {

    .share-operation {
      .basic-span;
      display: none;

      i {
        font-size: 16px;
        margin-right: 5px;

        &:hover {

        }
      }
    }

    .share-size {
      .basic-span;
      display: inline-block;
      width: 80px;
      text-align: left;
      margin-left: 20px;
    }

    .share-date {
      .basic-span;
    }
  }

  .more-btn {
    display: inline-block;
    vertical-align: middle;
    line-height: @base-height;
    padding: 0 15px;
  }

  &:hover {
    background-color: aliceblue;
    cursor: pointer;

    .share-operation {
      display: inline-block;
    }
  }

  .more-panel {
    border-top: 1px solid #eee;
    padding-left: 35px;

    .text {
      margin-left: 5px;
    }
    .cell-btn {
      border-top: 1px solid #eee;
      line-height: @inner-cell-height;
      vertical-align: middle;
    }
  }
}

@primary-color: #215891;
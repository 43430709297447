@base-height: 48px;
@inner-cell-height: 36px;

.widget-image-cache-panel {
  border-bottom: 1px solid #eee;
  &:last-child {
    border-bottom: none;
  }

  .basic-span {
    display: inline-block;
    vertical-align: middle;
    line-height: @base-height;
    margin-right: 10px;
  }

  .image-cache-icon {
    width: 24px;
  }

  .middle-part {

    height: @base-height;
    overflow: hidden;

    .image-cache-name-edit {
      .basic-span;

      width: 90%;
      input {
        width: 100%;
        height: 26px;
        display: inline-block;
        padding: 6px;
      }
    }

    .image-cache-name {
      .basic-span;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .right-part {

    .image-cache-operation {
      .basic-span;
      display: none;
      i {
        font-size: 16px;
        margin-right: 5px;

        &:hover {

        }
      }
    }
    .image-cache-size {
      .basic-span;
      display: inline-block;
      width: 80px;
      text-align: left;
      margin-left: 20px;
    }
    .image-cache-date {
      .basic-span;
    }
  }

  .more-btn {
    display: inline-block;
    vertical-align: middle;
    line-height: @base-height;
    padding: 0 15px;
  }

  &:hover {
    background-color: aliceblue;
    cursor: pointer;

    .image-cache-operation {
      display: inline-block;
    }
  }

  .more-panel {
    border-top: 1px solid #eee;
    padding-left: 32px;
    .cell-btn {
      border-top: 1px solid #eee;
      line-height: @inner-cell-height;
      vertical-align: middle;
      &:first-child {
        border: 0;
      }
    }
    .text {
      margin-left: 5px;
    }
    .matter-size {
      margin-left: 15px;
    }
  }
}

@primary-color: #215891;
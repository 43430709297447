.matter-sort-panel {
  display: flex;
  align-items: center;
  background-color: white;
  height: 48px;
  margin: 0;
  line-height: 48px;
  color: #999;

  .sort-part {
    &:hover {
      cursor: pointer;
      background-color: aliceblue;
    }
    &-name {
      flex-grow: 1;
      padding-left: 10px;
    }
    &-size {
      flex-shrink: 0;
      flex-basis: 90px;
      padding-left: 10px;
      cursor: pointer;
    }
    &-date {
      flex-shrink: 0;
      flex-basis: 130px;
      padding: 0 10px;
      cursor: pointer;
    }

  }
}

@primary-color: #215891;
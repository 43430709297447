@import "../assets/css/global/variables";

.pages-frame {

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;


  .pages-frame-inner {
    width: 100%;
    height: 100%;



  }




}

@primary-color: #215891;
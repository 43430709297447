
.app-frame-loading {

  position: fixed;
  left: 0;
  right: 0;

  background: #fafafa;
  color: #3a3a3a;

  top: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  .loading-box {
    width: 200px;
    height: 200px;
    text-align: center;

    .loading-icon {
      font-size: 48px;
    }

    .loading-text {
      margin-top: 20px;
    }
  }


}

@primary-color: #215891;
@media (min-width: 992px) {
  .visible-mobile {
    display: none !important;
  }

  .visible-pc {
    display: block !important;
  }
}

@media (max-width: 992px) {
  .visible-mobile {
    display: block !important;
  }

  .visible-pc {
    display: none !important;
  }
}

//浏览器滚动条样式

//滚动条样式
::-webkit-scrollbar {
  width: 10px;
  height: 10px
}

::-webkit-scrollbar-thumb {
  transition: all .3s ease;
  border-color: transparent;
  background-color: rgba(0, 0, 0, .1);
  z-index: 40
}

::-webkit-scrollbar-thumb:hover {
  transition: all .3s ease;
  background-color: rgba(0, 0, 0, .15)
}

::-webkit-scrollbar-corner {
  background-color: #eaeaeb
}

//页面的导航样式
.widget-tank-content-card {

  background: white;
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 15px;

  .loading-area {

    text-align: center;

    .loading-icon {
      font-size: 24px;
    }
  }

}

@primary-color: #215891;
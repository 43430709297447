.mobile-user {
  background: #fff;
  &:hover {
    background: aliceblue;
    cursor: pointer;
  }
  .panel {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #eee;
    .avatar {
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .username {
      flex-grow: 1;
      margin: 0 10px;
      font-size: 15px;
    }

    &-detail {
      padding: 10px 10px 10px 0;
      border-top: 1px solid #eee;
    }
  }
}

@primary-color: #215891;
@import "../../assets/css/global/variables.less";

.page-dashboard-index {

  figure {
    .echarts {
      width: 100%;
      height: 300px;
    }
  }

  .text-block {
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    border-radius: 5px;
    padding: 20px 15px 10px 15px;
    margin-bottom: 18px;

    .upper {

      .indicator {
        color: rgba(0, 0, 0, .45);
        font-size: 14px;
        line-height: 22px;
        height: 22px;
      }

      .amount {
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
        color: rgba(0, 0, 0, .85);
        margin-top: 4px;
        margin-bottom: 20px;
        font-size: 30px;
        line-height: 38px;
        height: 38px;

      }

      .rate {
        margin-right: 15px;
      }
    }

    .lower {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #eee;
      font-size: 14px;
    }
  }

  .figure-block {
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    border-radius: 5px;
    margin-bottom: 20px;

    .title {
      font-size: 18px;
      padding: 15px 20px;
      color: black;
      margin-bottom: 10px;
      border-bottom: 1px solid #eee;
    }

  }

  .list-rank {
    padding: 0 20px 10px 20px;

    ul {
      list-style: none;
      padding: 0;

      li {
        zoom: 1;
        margin-top: 16px;
        display: flex;
        align-items: center;

        .rank {
          border-radius: 20px;
          display: inline-block;
          font-size: 12px;
          font-weight: 600;
          margin-right: 16px;
          height: 20px;
          line-height: 20px;
          width: 20px;
          text-align: center;
          margin-top: 1.5px;

          background-color: #f5f5f5;

          &.top3 {
            background-color: #314659;
            color: #fff;
          }
        }

        .name {

          color: rgba(0, 0, 0, .65);
          font-size: 14px;
          line-height: 22px;
          flex: 1 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-right: 8px;

          &:hover {
            color: @brand-primary;
          }
        }

        .info {
          color: rgba(0, 0, 0, .65);
          font-size: 14px;
          line-height: 22px;
        }
      }
    }

  }
}

@primary-color: #215891;
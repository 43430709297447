@import "./variables.less";

.bg-primary{ background-color: @brand-primary; color: white;}
.bg-success{ background-color: @brand-success; color: white; }
.bg-info{ background-color: @brand-info; color: white; }
.bg-warning{ background-color: @brand-warning; color: white; }
.bg-danger{ background-color: @brand-danger; color: white; }
.bg-gray{ background-color: @brand-gray; color: white; }
.bg-laxative{ background-color: @brand-laxative; color: white; }


.text-primary{ color: @brand-primary;}
.text-success{ color: @brand-success; }
.text-info{ color: @brand-info; }
.text-warning{ color: @brand-warning; }
.text-danger{ color: @brand-danger; }
.text-gray{ color: @brand-gray; }
.text-laxative{ color: @brand-laxative; }
.text-theme { color: @primary-color; }

.bg-navy {  background-color: #001F3F;}
.bg-blue {  background-color: #0074D9;}
.bg-aqua {  background-color: #7FDBFF;}
.bg-aliceblue {  background-color: aliceblue;}
.bg-pink {  background-color: pink;}
.bg-azure {  background-color: azure;}
.bg-teal {  background-color: #39CCCC;}
.bg-olive {  background-color: #3D9970;}
.bg-green {  background-color: #2ECC40;}
.bg-lime {  background-color: #01FF70;}
.bg-yellow {  background-color: #FFDC00;}
.bg-pink { color: pink; }
.bg-orange {  background-color: #FF851B;}
.bg-red {  background-color: #FF4136;}
.bg-fuchsia {  background-color: #F012BE;}
.bg-purple {  background-color: #B10DC9;}
.bg-maroon {  background-color: #85144B;}
.bg-white {  background-color: #FFFFFF;}
.bg-gray {  background-color: #AAAAAA;}
.bg-silver {  background-color: #DDDDDD;}
.bg-silver-white {
  background-color: #EEEEEE;
}
.bg-black {  background-color: #111111;}

.bg-111{ background-color:#111 }
.bg-222{ background-color:#222 }
.bg-333{ background-color:#333 }
.bg-444{ background-color:#444 }
.bg-555{ background-color:#555 }
.bg-666{ background-color:#666 }
.bg-777{ background-color:#777 }
.bg-888{ background-color:#888 }
.bg-999{ background-color:#999 }
.bg-aaa{ background-color:#aaa }
.bg-bbb{ background-color:#bbb }
.bg-ccc{ background-color:#ccc }
.bg-ddd{ background-color:#ddd }
.bg-eee{ background-color:#eee }



/* Colors */
.navy {  color: #001F3F;}
.blue {  color: #0074D9;}
.aqua {  color: #7FDBFF;}
.teal {  color: #39CCCC;}
.olive {  color: #3D9970;}
.green {  color: #2ECC40;}
.lime {  color: #01FF70;}
.yellow {  color: #FFDC00;}
.pink { color: pink; }
.orange {  color: #FF851B;}
.red {  color: #FF4136;}
.fuchsia {  color: #F012BE;}
.purple {  color: #B10DC9;}
.maroon {  color: #85144B;}
.white {  color: #FFFFFF;}
.silver {  color: #DDDDDD;}
.gray {  color: #AAAAAA;}
.black {  color: #111111;}


.color-111{color:#111}
.color-222{color:#222}
.color-333{color:#333}
.color-444{color:#444}
.color-555{color:#555}
.color-666{color:#666}
.color-777{color:#777}
.color-888{color:#888}
.color-999{color:#999}
.color-aaa{color:#aaa}
.color-bbb{color:#bbb}
.color-ccc{color:#ccc}
.color-ddd{color:#ddd}
.color-eee{color:#eee}


.color-text {  color: #660E7A;}
.color-doc {  color: #295496;}
.color-xls {  color: #1E6C41;}
.color-ppt {  color: #D04324;}
.color-pdf {  color: #E40B0B;}
.color-audio {  color: #5bc0de;}
.color-video {  color: #5cb85c;}
.color-image {  color: #0074D9;}
.color-archive {  color: #4437f2;}

.color-light-active{ color: #ffc60c;}
.color-light-inactive{ color:#ccc;}



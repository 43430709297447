.user-login {

  padding-top: 150px;

  .welcome {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 40px;
  }

}

@primary-color: #215891;